// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../../common/Forms.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Attachment from "../../../attachment_chunks/Attachment.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SignaturePadView from "../../../common/views/SignaturePadView.js";
import * as PreVettingQuestionWidget from "./PreVettingQuestionWidget.js";

function getSignature(vnode, blob) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  } else {
    return Curry._2(Prelude.OptionExported.$$Option.map, blob, (function (b) {
                  var match = Attachment.make(b, "SIGNATURE");
                  var attachment = match[0];
                  return Prelude.thenResolve(match[1], (function () {
                                return attachment;
                              }));
                }));
  }
}

async function updateState(vnode, questionState) {
  var newState;
  newState = typeof questionState !== "object" ? "NotReady" : ({
        TAG: "Loaded",
        lastAnswer: questionState.lastAnswer,
        lastNewAnswer: questionState.lastNewAnswer,
        question: questionState.question,
        level: questionState.level,
        vessel: questionState.vessel
      });
  return Mithril.setState(vnode, newState);
}

function make() {
  return Mithril.view(Mithril.onupdate(Mithril.oncreate(Mithril.component("NotReady"), Forms.Input.focus), Forms.Input.focus), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                tmp = typeof match !== "object" ? Mithril.empty : React.jsx(SignaturePadView.make, {
                        onSign: (function (extra) {
                            var promise = getSignature(vnode, extra);
                            if (promise !== undefined) {
                              return Prelude.thenDo(Caml_option.valFromOption(promise), (function (attachment) {
                                            PreVettingQuestionWidget.Editor.save(vnode, (function () {
                                                    return {
                                                            value: {
                                                              NAME: "Value",
                                                              VAL: {
                                                                NAME: "Signature",
                                                                VAL: attachment
                                                              }
                                                            },
                                                            attachments: [attachment]
                                                          };
                                                  }), false, undefined);
                                          }));
                            } else {
                              console.warn("PreVettingSignatureQuestionWidget", "Signature not obtained");
                              return ;
                            }
                          })
                      });
                return React.jsx(PreVettingQuestionWidget.Editor.make, {
                            updateChildrenState: (function (extra) {
                                return updateState(vnode, extra);
                              }),
                            children: tmp
                          });
              }));
}

function make$1(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var question = vnode.attrs.question;
                var lastAnswer = vnode.attrs.lastAnswer;
                var lastNewAnswer = vnode.attrs.lastNewAnswer;
                var level = vnode.attrs.level;
                var vessel = vnode.attrs.vessel;
                var preVettingModule = vnode.attrs.preVettingModule;
                var isAlreadyAnswered = Belt_Option.isSome(lastNewAnswer);
                return React.jsx(PreVettingQuestionWidget.make, {
                            question: question,
                            level: level,
                            vessel: vessel,
                            lastNewAnswer: lastNewAnswer,
                            lastAnswer: lastAnswer,
                            children: ReactDOM.jsx("button", {
                                  children: React.jsx(Feather.make, {
                                        icon: "pen_tool"
                                      }),
                                  className: Mithril.$$class([
                                        [
                                          "btn btn-action",
                                          true
                                        ],
                                        [
                                          "active",
                                          isAlreadyAnswered
                                        ],
                                        [
                                          "bg-gray",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered
                                        ]
                                      ], undefined),
                                  style: {
                                    border: "none"
                                  },
                                  onClick: (function (extra) {
                                      return PreVettingQuestionWidget.startInspection(vnode, extra);
                                    })
                                }),
                            preVettingModule: preVettingModule
                          });
              }));
}

var Editor = {
  make: make
};

export {
  Editor ,
  make$1 as make,
}
/* Forms Not a pure module */
