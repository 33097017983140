// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../../Query.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Vessel from "../../../vessel/Vessel.js";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as LocalImage from "../../../../libs/LocalImage.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as TakePicture from "../../../common/views/TakePicture.js";
import * as DetailImages from "../../../common/views/DetailImages.js";
import * as Mithril__Route from "../../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as NavigationPills from "../../../common/views/NavigationPills.js";
import * as MarkdownDocumentId from "../../../markdown/MarkdownDocumentId.js";
import * as MarkdownDocumentPage from "../../../markdown/views/MarkdownDocumentPage.js";
import * as PreVettingQuestionWidget from "./PreVettingQuestionWidget.js";

async function updateState(vnode, questionState) {
  var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
  var step = Prelude.default(Mithril__Route.paramInt("step"), createNew ? 1 : 2);
  if (typeof questionState !== "object") {
    return Mithril.setState(vnode, "NotReady");
  }
  var vessel = questionState.vessel;
  var level = questionState.level;
  var question = questionState.question;
  var lastNewAnswer = questionState.lastNewAnswer;
  var lastAnswer = questionState.lastAnswer;
  var record = vnode.state.data;
  if (typeof record === "object") {
    return Mithril.setState(vnode, {
                TAG: "Loaded",
                vessel: vessel,
                lastNewAnswer: lastNewAnswer,
                lastAnswer: lastAnswer,
                question: question,
                document: record.document,
                level: level,
                pictures: record.pictures,
                step: record.step
              });
  }
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  return Prelude.thenDo(Query.read((newrecord.documents = {
                    TAG: "Get",
                    _0: Prelude.default(question.guidance_document_id, MarkdownDocumentId.$$null)
                  }, newrecord)), (function (param) {
                var $$document = Prelude.$$Array.first(param.documents);
                var pictures;
                if (createNew) {
                  pictures = [];
                } else if (lastNewAnswer !== undefined) {
                  var match = lastNewAnswer.value;
                  if (match !== undefined) {
                    if (typeof match === "object") {
                      if (match.NAME === "Value") {
                        var match$1 = match.VAL;
                        pictures = typeof match$1 === "object" ? (
                            match$1.NAME === "Pictures" ? match$1.VAL : []
                          ) : [];
                      } else {
                        pictures = [];
                      }
                    } else {
                      pictures = [];
                    }
                  } else {
                    pictures = [];
                  }
                } else {
                  pictures = [];
                }
                Mithril.setState(vnode, {
                      TAG: "Loaded",
                      vessel: vessel,
                      lastNewAnswer: lastNewAnswer,
                      lastAnswer: lastAnswer,
                      question: question,
                      document: $$document,
                      level: level,
                      pictures: pictures,
                      step: step
                    });
              }));
}

function getValue(vnode, param) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return {
            attachments: []
          };
  }
  var pictures = match.pictures;
  return {
          value: {
            NAME: "Value",
            VAL: {
              NAME: "Pictures",
              VAL: pictures
            }
          },
          attachments: pictures
        };
}

function processAttachment(vnode, _image, attachment, param) {
  var match = vnode.state.data;
  if (typeof match === "object") {
    return Mithril.setState(vnode, {
                TAG: "Loaded",
                vessel: match.vessel,
                lastNewAnswer: match.lastNewAnswer,
                lastAnswer: match.lastAnswer,
                question: match.question,
                document: match.document,
                level: match.level,
                pictures: Curry._2(Prelude.$$Array.concat, match.pictures, [attachment]),
                step: 2
              });
  }
  console.log("Error");
}

function make() {
  return Mithril.view(Mithril.component("NotReady"), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var step = match.step;
                  var pictures = match.pictures;
                  var $$document = match.document;
                  var tmp$1;
                  if (step !== 1) {
                    if (step !== 2) {
                      tmp$1 = [];
                    } else {
                      var attachment = Prelude.$$Array.first(pictures);
                      tmp$1 = [
                        ReactDOM.jsx("p", {
                              children: match.question.name
                            }),
                        attachment !== undefined ? React.jsx(LocalImage.Resource.make, {
                                resource: attachment.id
                              }) : Mithril.empty,
                        ReactDOM.jsx("div", {
                              className: "divider xl blank"
                            }),
                        React.jsx(DetailImages.make, {
                              deleteAttachment: (async function (v1, param) {
                                  console.log(v1);
                                }),
                              attachments: Curry._2(Prelude.$$Array.sliceToEnd, pictures, 1)
                            })
                      ];
                    }
                  } else {
                    var last = Curry._2(Prelude.OptionExported.$$Option.orElse, match.lastNewAnswer, match.lastAnswer);
                    var tmp$2;
                    if (last !== undefined) {
                      var attachment$1 = Prelude.$$Array.first(last.evidences);
                      tmp$2 = ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("h4", {
                                    children: "Example",
                                    className: "text-center"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank"
                                  }),
                              ReactDOM.jsxs("figure", {
                                    children: [
                                      attachment$1 !== undefined ? React.jsx(LocalImage.Resource.make, {
                                              resource: attachment$1.id
                                            }) : Mithril.empty,
                                      ReactDOM.jsx("div", {
                                            className: "divider blank xl"
                                          }),
                                      ReactDOM.jsx("figcaption", {
                                            children: "The photo should look like this",
                                            className: "figure-caption text-center"
                                          })
                                    ],
                                    className: "figure"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank xl"
                                  })
                            ]
                          });
                    } else {
                      tmp$2 = Mithril.empty;
                    }
                    tmp$1 = [
                      $$document !== undefined ? React.jsx(MarkdownDocumentPage.MarkdownDocumentView.make, {
                              document: $$document
                            }) : Mithril.empty,
                      tmp$2
                    ];
                  }
                  tmp = Curry._2(Prelude.$$Array.concat, [React.jsx(NavigationPills.make, {
                              currentStep: step - 1 | 0,
                              stepCount: 1
                            })], tmp$1);
                }
                return React.jsx(PreVettingQuestionWidget.Editor.make, {
                            getValue: (function (extra) {
                                return getValue(vnode, extra);
                              }),
                            isDirty: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var pictures = match.pictures;
                                if (createNew) {
                                  return Caml_obj.notequal(pictures, []);
                                }
                                var lastNewAnswer = match.lastNewAnswer;
                                if (lastNewAnswer !== undefined) {
                                  return Caml_obj.notequal(lastNewAnswer.evidences, pictures);
                                } else {
                                  return false;
                                }
                              }),
                            updateChildrenState: (function (extra) {
                                return updateState(vnode, extra);
                              }),
                            bottom: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return Mithril.empty;
                                }
                                var step = match.step;
                                var question = match.question;
                                var vessel = match.vessel;
                                if (step !== 1) {
                                  if (step !== 2) {
                                    return Mithril.empty;
                                  } else {
                                    return ReactDOM.jsxs("div", {
                                                children: [
                                                  React.jsxs(TakePicture.make, {
                                                        role: "EVIDENCE",
                                                        requireLandscape: question.require_landscape_pictures,
                                                        style: {
                                                          height: "3rem",
                                                          marginRight: "0.66666667%",
                                                          width: "66%"
                                                        },
                                                        children: [
                                                          React.jsx(Feather.make, {
                                                                icon: "camera"
                                                              }),
                                                          "Add detail pictures"
                                                        ],
                                                        onCapture: (function (extra, extra$1, extra$2) {
                                                            return processAttachment(vnode, extra, extra$1, extra$2);
                                                          }),
                                                        useEmbeddedCamera: Vessel.getCameraConf(vessel),
                                                        resolution: vessel.max_pic_resolution
                                                      }),
                                                  ReactDOM.jsxs("button", {
                                                        children: [
                                                          React.jsx(Feather.make, {
                                                                icon: "save"
                                                              }),
                                                          "Save"
                                                        ],
                                                        className: "btn btn-primary col-4",
                                                        style: {
                                                          height: "3rem"
                                                        },
                                                        onClick: (function (extra) {
                                                            return PreVettingQuestionWidget.Editor.save(vnode, (function (extra) {
                                                                          return getValue(vnode, extra);
                                                                        }), false, extra);
                                                          })
                                                      })
                                                ],
                                                className: "columns m-0"
                                              });
                                  }
                                } else {
                                  return React.jsxs(TakePicture.make, {
                                              role: "MAIN_EVIDENCE",
                                              requireLandscape: question.require_landscape_pictures,
                                              className: "btn btn-primary btn-block btn-lg t-continue",
                                              children: [
                                                "Take picture",
                                                React.jsx(Feather.make, {
                                                      icon: "camera",
                                                      className: "ml-2"
                                                    })
                                              ],
                                              onCapture: (function (extra, extra$1, extra$2) {
                                                  return processAttachment(vnode, extra, extra$1, extra$2);
                                                }),
                                              onCameraFailure: (function (extra) {
                                                  console.log(vnode);
                                                }),
                                              useEmbeddedCamera: Vessel.getCameraConf(vessel),
                                              resolution: vessel.max_pic_resolution
                                            });
                                }
                              }),
                            children: tmp
                          });
              }));
}

function make$1(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var question = vnode.attrs.question;
                var lastAnswer = vnode.attrs.lastAnswer;
                var lastNewAnswer = vnode.attrs.lastNewAnswer;
                var level = vnode.attrs.level;
                var vessel = vnode.attrs.vessel;
                var preVettingModule = vnode.attrs.preVettingModule;
                var isAlreadyAnswered = Belt_Option.isSome(lastNewAnswer);
                return React.jsx(PreVettingQuestionWidget.make, {
                            question: question,
                            level: level,
                            vessel: vessel,
                            lastNewAnswer: lastNewAnswer,
                            lastAnswer: lastAnswer,
                            children: ReactDOM.jsx("button", {
                                  children: React.jsx(Feather.make, {
                                        icon: "camera"
                                      }),
                                  className: Mithril.$$class([
                                        [
                                          "btn btn-action",
                                          true
                                        ],
                                        [
                                          "active",
                                          isAlreadyAnswered
                                        ],
                                        [
                                          "bg-gray",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered
                                        ]
                                      ], undefined),
                                  style: {
                                    border: "none"
                                  },
                                  onClick: (function (extra) {
                                      return PreVettingQuestionWidget.startInspection(vnode, extra);
                                    })
                                }),
                            preVettingModule: preVettingModule
                          });
              }));
}

var Editor = {
  make: make
};

export {
  Editor ,
  make$1 as make,
}
/* Query Not a pure module */
