// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Answer from "../Answer.js";
import * as Finding from "../Finding.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as AnswerId from "../AnswerId.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as FindingId from "../FindingId.js";
import * as QuestionId from "../QuestionId.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as CommentDetails from "../../comments/views/CommentDetails.js";
import * as PreVettingQuery from "../../prevetting/PreVettingQuery.js";
import * as PreVettingAnswer from "../../prevetting/PreVettingAnswer.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var question_name = vnode.attrs.question_name;
                var comments = vnode.attrs.comments;
                return ReactDOM.jsx("div", {
                            children: ReactDOM.jsxs("div", {
                                  children: [
                                    ReactDOM.jsx("h5", {
                                          children: question_name
                                        }),
                                    comments.map(function (comment) {
                                          var profile = comment.profile;
                                          return React.jsxKeyed(CommentDetails.make, {
                                                      author: profile !== undefined ? UserProfile.nameWithRank(profile) : comment.author.first_name + " " + comment.author.last_name,
                                                      color: "initial",
                                                      body: comment.body,
                                                      date: comment.timestamp,
                                                      isNew: true
                                                    }, comment.id, undefined);
                                        })
                                  ],
                                  className: "tile-content"
                                }),
                            className: "tile p-0"
                          });
              }));
}

var FindingCommentCard = {
  make: make
};

async function getPairFindingIdQuestion(findingIds, preVettingModule) {
  if (preVettingModule !== undefined) {
    var Def = {
      preVettingModule: preVettingModule
    };
    var PreVettingQuery$1 = PreVettingQuery.Make(Def);
    var init = PreVettingQuery$1.makeRead();
    var match = await PreVettingQuery$1.read({
          levels: init.levels,
          answers: init.answers,
          newAnswers: init.newAnswers,
          risqVettingPeriods: init.risqVettingPeriods,
          questions: init.questions,
          findings: {
            TAG: "In",
            _0: findingIds
          },
          risqQuestionLocations: init.risqQuestionLocations
        });
    var findings = match.findings;
    var init$1 = PreVettingQuery$1.makeRead();
    var match$1 = await PreVettingQuery$1.read({
          levels: init$1.levels,
          answers: {
            TAG: "In",
            _0: findings.map(Finding.byDetectedWith)
          },
          newAnswers: init$1.newAnswers,
          risqVettingPeriods: init$1.risqVettingPeriods,
          questions: init$1.questions,
          findings: init$1.findings,
          risqQuestionLocations: init$1.risqQuestionLocations
        });
    var answers = match$1.answers;
    var init$2 = PreVettingQuery$1.makeRead();
    var match$2 = await PreVettingQuery$1.read({
          levels: init$2.levels,
          answers: init$2.answers,
          newAnswers: init$2.newAnswers,
          risqVettingPeriods: init$2.risqVettingPeriods,
          questions: {
            TAG: "In",
            _0: answers.map(PreVettingAnswer.byQuestion)
          },
          findings: init$2.findings,
          risqQuestionLocations: init$2.risqQuestionLocations
        });
    var questionById = QuestionId.$$Map.fromArray(match$2.questions.map(function (q) {
              return [
                      q.id,
                      q
                    ];
            }));
    var questionByAnswerId = AnswerId.$$Map.fromArray(answers.map(function (a) {
              return [
                      a.id,
                      Curry._2(Prelude.OptionExported.$$Option.getWithDefault, QuestionId.$$Map.get(questionById, a.question_id), Question.Defaults.$$null)
                    ];
            }));
    return findings.map(function (f) {
                return [
                        f.id,
                        Curry._2(Prelude.OptionExported.$$Option.getWithDefault, AnswerId.$$Map.get(questionByAnswerId, f.created_by_id), Question.Defaults.$$null)
                      ];
              });
  }
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$3 = await Query.read((newrecord.findings = {
          TAG: "In",
          _0: findingIds
        }, newrecord));
  var findings$1 = match$3.findings;
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$4 = await Query.read((newrecord$1.answers = {
          TAG: "In",
          _0: findings$1.map(Finding.byDetectedWith)
        }, newrecord$1));
  var answers$1 = match$4.answers;
  var newrecord$2 = Caml_obj.obj_dup(Query.makeRead());
  var match$5 = await Query.read((newrecord$2.questions = {
          TAG: "In",
          _0: answers$1.map(Answer.byQuestion)
        }, newrecord$2));
  var questionById$1 = QuestionId.$$Map.fromArray(match$5.questions.map(function (q) {
            return [
                    q.id,
                    q
                  ];
          }));
  var questionByAnswerId$1 = AnswerId.$$Map.fromArray(answers$1.map(function (a) {
            return [
                    a.id,
                    Curry._2(Prelude.OptionExported.$$Option.getWithDefault, QuestionId.$$Map.get(questionById$1, a.question_id), Question.Defaults.$$null)
                  ];
          }));
  return findings$1.map(function (f) {
              return [
                      f.id,
                      Curry._2(Prelude.OptionExported.$$Option.getWithDefault, AnswerId.$$Map.get(questionByAnswerId$1, f.created_by_id), Question.Defaults.$$null)
                    ];
            });
}

async function populate(vnode) {
  var findingIds = vnode.attrs.finding_ids;
  var findingIdQuestion = await getPairFindingIdQuestion(findingIds, undefined);
  var risqFindingIdQuestion = await getPairFindingIdQuestion(findingIds, "RISQ");
  var questionByFindingId = FindingId.$$Map.fromArray(Curry._2(Prelude.$$Array.concat, findingIdQuestion, risqFindingIdQuestion));
  var findingIds$1 = FindingId.$$Map.keysToArray(questionByFindingId);
  return Mithril.setState(vnode, {
              TAG: "Found",
              findingIds: findingIds$1,
              questionByFindingId: questionByFindingId
            });
}

function make$1(param) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), populate), (function (vnode) {
                var comments = vnode.attrs.comments;
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var questionByFindingId = match.questionByFindingId;
                return ReactDOM.jsx("div", {
                            children: match.findingIds.map(function (findingId) {
                                  var findingComments = Curry._2(Prelude.$$Array.keep, comments, (function (c) {
                                          return Caml_obj.equal(c.object_id, findingId);
                                        }));
                                  var question_name = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Curry._2(Prelude.OptionExported.$$Option.map, FindingId.$$Map.get(questionByFindingId, findingId), (function (q) {
                                              return q.name;
                                            })), "");
                                  return React.jsxKeyed(make, {
                                              question_name: question_name,
                                              comments: findingComments
                                            }, findingId, undefined);
                                }),
                            className: "t-findings"
                          });
              }));
}

export {
  FindingCommentCard ,
  getPairFindingIdQuestion ,
  populate ,
  make$1 as make,
}
/* Query Not a pure module */
