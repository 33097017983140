// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Query from "../../../Query.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as VesselId from "../../../vessel/VesselId.js";
import * as AppSchema from "../../../AppSchema.js";
import * as LocalFile from "../../../../libs/LocalFile.js";
import * as RisqQuery from "../../../RisqQuery.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AttachmentId from "../../../attachment_chunks/AttachmentId.js";
import * as UploadPayload from "../UploadPayload.js";
import * as UploadSummary from "../../views/UploadSummary.js";
import * as UploadActionable from "../UploadActionable.js";

function _getSucceededChunksIds(results) {
  return Curry._2(Prelude.$$Array.keepMap, Curry._2(Prelude.$$Array.keepMap, results, Prelude.Result.ok), (function (actionable) {
                if (typeof actionable !== "object" || actionable.TAG !== "UploadFutureAttachmentChunk") {
                  return ;
                } else {
                  return Caml_option.some(actionable.chunkId);
                }
              }));
}

function _getSucceededFutureIds(results) {
  return Curry._2(Prelude.$$Array.keepMap, Curry._2(Prelude.$$Array.keepMap, results, Prelude.Result.ok), (function (actionable) {
                if (typeof actionable !== "object" || actionable.TAG !== "UploadFutureAttachmentChunk") {
                  return ;
                } else {
                  return Caml_option.some(actionable.future.id);
                }
              }));
}

async function _updateFutureAttachments(results) {
  var futuresIds = _getSucceededFutureIds(results);
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.futureAttachments = {
          TAG: "In",
          _0: futuresIds
        }, newrecord));
  var futureAttachments = match.futureAttachments;
  var initial = AppSchema.FutureAttachments.updateMany(AppSchema.Core.make(), futureAttachments);
  var futures = AttachmentId.$$Map.fromArray(futureAttachments.map(function (f) {
            return [
                    f.id,
                    f
                  ];
          }));
  var succeded = Curry._2(Prelude.$$Array.keepMap, results, Prelude.Result.ok);
  Curry._2(Prelude.$$Array.forEach, succeded, (function (actionable) {
          if (typeof actionable !== "object") {
            return ;
          }
          if (actionable.TAG !== "UploadFutureAttachmentChunk") {
            return ;
          }
          var chunkId = actionable.chunkId;
          var future = actionable.future;
          var future$1 = Prelude.default(AttachmentId.$$Map.get(futures, future.id), future);
          var match = Curry._2(Prelude.$$Array.partition, future$1.localChunks, (function (c) {
                  return Caml_obj.equal(c.id, chunkId);
                }));
          var sentChunks = Curry._2(Prelude.$$Array.concat, future$1.sentChunks, match[0]);
          var future_id = future$1.id;
          var future_vessel_id = future$1.vessel_id;
          var future_allChunks = future$1.allChunks;
          var future_localChunks = match[1];
          var future_checksum = future$1.checksum;
          var future_checksum_algorithm = future$1.checksum_algorithm;
          var future_size = future$1.size;
          var future_payload = future$1.payload;
          var future_mimetype = future$1.mimetype;
          var future_created_at = future$1.created_at;
          var future$2 = {
            id: future_id,
            vessel_id: future_vessel_id,
            allChunks: future_allChunks,
            localChunks: future_localChunks,
            sentChunks: sentChunks,
            checksum: future_checksum,
            checksum_algorithm: future_checksum_algorithm,
            size: future_size,
            payload: future_payload,
            mimetype: future_mimetype,
            created_at: future_created_at
          };
          AttachmentId.$$Map.set(futures, future_id, future$2);
        }));
  var futures$1 = AttachmentId.$$Map.valuesToArray(futures);
  var match$1 = Curry._2(Prelude.$$Array.partition, futures$1, (function (f) {
          return Prelude.$$Array.isEmpty(f.localChunks);
        }));
  var chunkIds = Prelude.$$Array.flatMap(match$1[0], (function (f) {
            return f.allChunks;
          })).map(function (c) {
        return c.id;
      });
  await LocalFile.deleteMany(chunkIds);
  var $$final = AppSchema.FutureAttachments.updateMany(AppSchema.Core.make(), match$1[1]);
  var newrecord$1 = Caml_obj.obj_dup(Query.makeWrite());
  newrecord$1.futureAttachments = AppSchema.FutureAttachments.getActions(initial, $$final);
  Query.ActionLogging.info("UploadStages__Finalizing" + ": updating futures", newrecord$1);
  await Query.write(newrecord$1);
}

async function next(results) {
  await _updateFutureAttachments(results);
  var summary = UploadSummary.summarize(results);
  var succeeded = summary.succeeded;
  if (!UploadSummary.succeeded(summary)) {
    return {
            TAG: "Done",
            _0: results
          };
  }
  var eot = await UploadActionable.execute({
        TAG: "EOT",
        _0: VesselId.$$Set.toArray(VesselId.$$Set.fromArray(succeeded.map(UploadPayload.getVessel).map(function (v) {
                      return v.id;
                    })))
      });
  if (eot.TAG !== "Ok") {
    return {
            TAG: "Done",
            _0: results
          };
  }
  var inspections = Curry._2(Prelude.$$Array.keepMap, succeeded, UploadPayload.getInspection);
  var newUserProfiles = Curry._2(Prelude.$$Array.keepMap, succeeded, UploadPayload.getUserProfile);
  var newComments = Curry._2(Prelude.$$Array.keepMap, succeeded, UploadPayload.getNewComment);
  var updatedComments = Curry._2(Prelude.$$Array.keepMap, succeeded, UploadPayload.getUpdatedComment);
  var newCompanyFormSubmissions = Curry._2(Prelude.$$Array.keepMap, succeeded, UploadPayload.getCompanyFormSubmission);
  var draftLevelSubmissions = Curry._2(Prelude.$$Array.keepMap, succeeded, UploadPayload.getLevelSubmission);
  var answers = Curry._2(Prelude.$$Array.keepMap, succeeded, UploadPayload.getAnswer);
  var risqAnswers = Curry._2(Prelude.$$Array.keepMap, succeeded, UploadPayload.getRisqAnswer);
  var procedureUpdates = Curry._2(Prelude.$$Array.keepMap, succeeded, UploadPayload.getProcedureUpdate);
  var readNotificationEntries = Curry._2(Prelude.$$Array.keepMap, succeeded, UploadPayload.getReadNotificationEntry);
  var newrecord = Caml_obj.obj_dup(Query.makeWrite());
  await Prelude.PromisedResult.scream(Query.Safe.write((newrecord.readNotificationEntries = readNotificationEntries.map(function (entry) {
                  return {
                          TAG: "Delete",
                          _0: entry.id
                        };
                }), newrecord.procedureUpdates = procedureUpdates.map(function (procedure) {
                  return {
                          TAG: "Delete",
                          _0: procedure.id
                        };
                }), newrecord.newAnswers = answers.map(function (i) {
                  return {
                          TAG: "Delete",
                          _0: i.id
                        };
                }), newrecord.draftLevelSubmissions = draftLevelSubmissions.map(function (s) {
                  return {
                          TAG: "Delete",
                          _0: s.id
                        };
                }), newrecord.draftCompanyFormSubmissions = newCompanyFormSubmissions.map(function (s) {
                  return {
                          TAG: "Delete",
                          _0: s.id
                        };
                }), newrecord.newCompanyFormSubmissions = newCompanyFormSubmissions.map(function (s) {
                  return {
                          TAG: "Delete",
                          _0: s.id
                        };
                }), newrecord.newUserProfiles = newUserProfiles.map(function (p) {
                  return {
                          TAG: "Delete",
                          _0: p.id
                        };
                }), newrecord.updatedComments = updatedComments.map(function (c) {
                  return {
                          TAG: "Delete",
                          _0: c.id
                        };
                }), newrecord.newComments = newComments.map(function (c) {
                  return {
                          TAG: "Delete",
                          _0: c.id
                        };
                }), newrecord.newInspections = inspections.map(function (i) {
                  return {
                          TAG: "Delete",
                          _0: i.id
                        };
                }), newrecord)));
  var init = RisqQuery.makeWrite();
  await Prelude.PromisedResult.scream(RisqQuery.Safe.write({
            levels: init.levels,
            answers: init.answers,
            newAnswers: risqAnswers.map(function (i) {
                  return {
                          TAG: "Delete",
                          _0: i.id
                        };
                }),
            risqVettingPeriods: init.risqVettingPeriods,
            questions: init.questions,
            findings: init.findings,
            risqQuestionLocations: init.risqQuestionLocations
          }));
  return {
          TAG: "Done",
          _0: results
        };
}

export {
  _getSucceededChunksIds ,
  _getSucceededFutureIds ,
  _updateFutureAttachments ,
  next ,
}
/* Query Not a pure module */
