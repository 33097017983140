// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as LevelType from "../../tree/LevelType.js";
import * as PageHeader from "../../common/views/PageHeader.js";
import * as PageLayout from "../../common/views/PageLayout.js";
import * as Hyphenated from "hyphenated";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as UserProfileCard from "../../accounts/components/UserProfileCard.js";
import * as ToastNotification from "../../common/components/ToastNotification.js";

async function update(vnode) {
  var vesselId = VesselId.Route.param("vesselId");
  var system = State__Memory.Current.get();
  var currentProfile = system.profile;
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match = await Query.read((newrecord.levelTypes = LevelType.ofKind("sire"), newrecord));
  var newrecord$1 = Caml_obj.obj_dup(Query.makeRead());
  var match$1 = await Query.read((newrecord$1.levels = {
          TAG: "And",
          _0: Level.ofVessel(vesselId),
          _1: {
            TAG: "AnyOf",
            _0: "kind",
            _1: match.levelTypes.map(function (param) {
                  return param.id;
                })
          }
        }, newrecord$1));
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              vesselId: vesselId,
              selectedLevel: Prelude.default(Prelude.$$Array.first(match$1.levels), Level.Defaults.$$null),
              currentProfile: currentProfile
            });
}

function make(param) {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), update), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return React.jsx(PageLayout.make, {
                              header: Caml_option.some(React.jsx(PageHeader.Full.make, {
                                        content: {
                                          NAME: "Title",
                                          VAL: "SIRE 2.0"
                                        }
                                      })),
                              children: Caml_option.some(React.jsx(ToastNotification.make, {}))
                            });
                }
                var currentProfile = match.currentProfile;
                var selectedLevel = match.selectedLevel;
                var vesselId = match.vesselId;
                return React.jsxs(PageLayout.make, {
                            header: Caml_option.some(React.jsx(PageHeader.Full.make, {
                                      content: {
                                        NAME: "Title",
                                        VAL: "SIRE 2.0"
                                      }
                                    })),
                            footer: Caml_option.some(ReactDOM.jsx("button", {
                                      children: "Continue",
                                      className: "tw-m-0 tw-border-[0.05rem] tw-border-solid tw-border-[#4b48d6] tw-rounded-[0.1rem] tw-cursor-pointer tw-leading-[1.2rem] tw-text-center tw-no-underline [transition:background_.2s,_border_.2s,_box-shadow_.2s,_color_.2s] tw-select-none tw-bg-[#5755d9] tw-text-white tw-text-[1rem] tw-h-12 tw-px-6 tw-py-[0.85rem] tw-w-full disabled:tw-opacity-50",
                                      disabled: Curry._1(Prelude.OptionExported.$$Option.isNone, currentProfile),
                                      onClick: (function (param) {
                                          Mithril$1.route.set(URLs.vesselLevelByKind(vesselId, selectedLevel.id, "sire", undefined, undefined, undefined, undefined, undefined, undefined));
                                        })
                                    })),
                            children: [
                              React.jsx(ToastNotification.make, {}),
                              ReactDOM.jsxs("p", {
                                    children: [
                                      ReactDOM.jsx("strong", {
                                            children: "Welcome to the Kaiko SIRE 2.0 module!"
                                          }),
                                      ReactDOM.jsx("p", {
                                            children: Hyphenated.hyphenated("This powerful tool has been specially designed to help your team prepare for the SIRE 2.0 vetting exercise. Our self-assessment feature allows vessels to get ready for the inspection conducted by regulators, by providing a library that mirrors the SIRE 2.0 questions chapter by chapter. It also allows different officers to conduct self-assessments ahead of the OCIMF inspection."),
                                            className: "tw-mt-2"
                                          })
                                    ],
                                    className: "tw-leading-6 tw-text-[.9em] tw-m-2"
                                  }),
                              ReactDOM.jsx("div", {
                                    children: currentProfile !== undefined ? [
                                        ReactDOM.jsx("p", {
                                              children: "Your current profile is:",
                                              className: "tw-m-0 tw-font-bold"
                                            }),
                                        React.jsx(UserProfileCard.make, {
                                              profile: currentProfile
                                            }),
                                        React.jsx(Mithril.Link.make, {
                                              href: "/user-profiles",
                                              className: "tw-bg-white tw-border-[0.05rem] tw-border-solid tw-rounded-[0.1rem] tw-cursor-pointer tw-inline-block tw-leading-[1.2rem] tw-outline-[none] tw-text-center tw-no-underline [transition:background_.2s,_border_.2s,_box-shadow_.2s,_color_.2s] tw-select-none tw-align-middle tw-whitespace-nowrap !tw-mb-[0.4rem] tw-text-[#4b48d6] tw-text-[0.7rem] tw-h-[1.4rem] tw-px-3 tw-py-[0.05rem]",
                                              children: "Switch profile"
                                            })
                                      ] : [
                                        ReactDOM.jsx("p", {
                                              children: "You need a profile in order to continue.",
                                              className: "tw-m-0 tw-font-bold tw-text-[#ffb700]"
                                            }),
                                        React.jsx(Mithril.Link.make, {
                                              href: "/user-profiles",
                                              className: "tw-border-[0.05rem] tw-border-solid tw-rounded-[0.1rem] tw-cursor-pointer tw-inline-block tw-leading-[1.2rem] tw-text-center [transition:background_.2s,_border_.2s,_box-shadow_.2s,_color_.2s] tw-select-none tw-align-middle tw-whitespace-nowrap !tw-mb-[0.4rem] tw-bg-[#5755d9] tw-border-[#4b48d6] tw-text-[0.7rem] tw-h-[1.4rem] tw-px-3 tw-py-[0.05rem] tw-text-white",
                                              children: "Manage profiles"
                                            })
                                      ],
                                    className: "tw-flex tw-items-center tw-flex-col tw-p-2 tw-mb-2"
                                  })
                            ]
                          });
              }));
}

export {
  update ,
  make ,
}
/* URLs Not a pure module */
