// Generated by ReScript, PLEASE EDIT WITH CARE


var _map = {"RISQ":"RISQ"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var fromJs = tFromJs;

var toJs = tToJs;

export {
  tToJs ,
  tFromJs ,
  fromJs ,
  toJs ,
}
/* No side effect */
