// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../../common/Forms.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Mithril__Route from "../../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as DateQuestionUtils from "../../../tree/views/questions/DateQuestionUtils.js";
import * as PreVettingQuestionWidget from "./PreVettingQuestionWidget.js";

async function updateState(vnode, questionState) {
  var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
  var newState;
  if (typeof questionState !== "object") {
    newState = "NotReady";
  } else {
    var lastNewAnswer = questionState.lastNewAnswer;
    var match = vnode.state.data;
    var tmp;
    if (typeof match !== "object") {
      if (createNew) {
        tmp = [];
      } else if (lastNewAnswer !== undefined) {
        var match$1 = lastNewAnswer.value;
        if (match$1 !== undefined) {
          if (typeof match$1 === "object") {
            if (match$1.NAME === "Value") {
              var match$2 = match$1.VAL;
              tmp = typeof match$2 === "object" ? (
                  match$2.NAME === "MultipleDates" ? match$2.VAL.map(function (d) {
                          return [
                                  Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, d, DateQuestionUtils.formatDate), ""),
                                  d
                                ];
                        }) : []
                ) : [];
            } else {
              tmp = [];
            }
          } else {
            tmp = [];
          }
        } else {
          tmp = [];
        }
      } else {
        tmp = [];
      }
    } else {
      tmp = match.values;
    }
    newState = {
      TAG: "Loaded",
      lastAnswer: questionState.lastAnswer,
      lastNewAnswer: lastNewAnswer,
      question: questionState.question,
      level: questionState.level,
      values: tmp
    };
  }
  return Mithril.setState(vnode, newState);
}

function make() {
  return Mithril.view(Mithril.oncreate(Mithril.component("NotReady"), Forms.Input.focus), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var values = match.values;
                  var question = match.question;
                  var match$1 = question.kind;
                  tmp = typeof match$1 === "object" && match$1.NAME === "MultipleDatesQuestion" ? ReactDOM.jsxs("div", {
                          children: [
                            ReactDOM.jsx("div", {
                                  children: question.name,
                                  className: "tile-content text-center text-small"
                                }),
                            ReactDOM.jsx("br", {}),
                            match$1.VAL.labels.map(function (label, index) {
                                  var match = Prelude.default(Curry._2(Prelude.$$Array.get, values, index), [
                                        "",
                                        undefined
                                      ]);
                                  return ReactDOM.jsxs("div", {
                                              children: [
                                                ReactDOM.jsx("br", {}),
                                                ReactDOM.jsx("label", {
                                                      children: label,
                                                      className: "form-label",
                                                      htmlFor: question.id + String(index)
                                                    }),
                                                ReactDOM.jsx("input", {
                                                      className: "form-input",
                                                      id: question.id + String(index),
                                                      type: "date",
                                                      value: match[0],
                                                      onChange: (function (extra) {
                                                          var match = vnode.state.data;
                                                          if (typeof match !== "object") {
                                                            return ;
                                                          }
                                                          var question = match.question;
                                                          var match$1 = question.kind;
                                                          return Mithril.setState(vnode, {
                                                                      TAG: "Loaded",
                                                                      lastAnswer: match.lastAnswer,
                                                                      lastNewAnswer: match.lastNewAnswer,
                                                                      question: question,
                                                                      level: match.level,
                                                                      values: typeof match$1 === "object" ? (
                                                                          match$1.NAME === "MultipleDatesQuestion" ? match$1.VAL.labels.map(function (param, index) {
                                                                                  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[id='" + question.id + String(index) + "']")), (function (v) {
                                                                                                    return [
                                                                                                            v,
                                                                                                            DateQuestionUtils.dateFromString(v)
                                                                                                          ];
                                                                                                  })), [
                                                                                              "",
                                                                                              undefined
                                                                                            ]);
                                                                                }) : []
                                                                        ) : []
                                                                    });
                                                        })
                                                    })
                                              ]
                                            });
                                })
                          ],
                          className: "form-group"
                        }) : Mithril.empty;
                }
                return React.jsx(PreVettingQuestionWidget.Editor.make, {
                            getValue: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return {
                                          attachments: []
                                        };
                                }
                                var dates = match.values.map(function (param) {
                                      return param[1];
                                    });
                                return {
                                        value: {
                                          NAME: "Value",
                                          VAL: {
                                            NAME: "MultipleDates",
                                            VAL: dates
                                          }
                                        },
                                        attachments: []
                                      };
                              }),
                            allowSave: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var match$1 = match.question.kind;
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "MultipleDatesQuestion") {
                                  return false;
                                }
                                var dates = match.values.map(function (param) {
                                      return param[1];
                                    });
                                return match$1.VAL.labels.length === Prelude.$$Array.keepSome(dates).length;
                              }),
                            isDirty: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var values = match.values;
                                var lastNewAnswer = match.lastNewAnswer;
                                if (createNew) {
                                  return Curry._2(Prelude.$$Array.some, values, (function (param) {
                                                return Curry._1(Prelude.OptionExported.$$Option.isSome, param[1]);
                                              }));
                                }
                                if (lastNewAnswer === undefined) {
                                  return false;
                                }
                                var match$1 = lastNewAnswer.value;
                                if (match$1 === undefined) {
                                  return false;
                                }
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "Value") {
                                  return false;
                                }
                                var match$2 = match$1.VAL;
                                if (typeof match$2 !== "object") {
                                  return false;
                                }
                                if (match$2.NAME !== "MultipleDates") {
                                  return false;
                                }
                                var dates = values.map(function (param) {
                                      return param[1];
                                    });
                                return Caml_obj.notequal(match$2.VAL, dates);
                              }),
                            updateChildrenState: (function (extra) {
                                return updateState(vnode, extra);
                              }),
                            children: tmp
                          });
              }));
}

function make$1(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var question = vnode.attrs.question;
                var lastAnswer = vnode.attrs.lastAnswer;
                var lastNewAnswer = vnode.attrs.lastNewAnswer;
                var level = vnode.attrs.level;
                var vessel = vnode.attrs.vessel;
                var preVettingModule = vnode.attrs.preVettingModule;
                var isAlreadyAnswered = Belt_Option.isSome(lastNewAnswer);
                return React.jsx(PreVettingQuestionWidget.make, {
                            question: question,
                            level: level,
                            vessel: vessel,
                            lastNewAnswer: lastNewAnswer,
                            lastAnswer: lastAnswer,
                            children: ReactDOM.jsx("button", {
                                  children: React.jsx(Feather.make, {
                                        icon: "calendar"
                                      }),
                                  className: Mithril.$$class([
                                        [
                                          "btn btn-action",
                                          true
                                        ],
                                        [
                                          "active",
                                          isAlreadyAnswered
                                        ],
                                        [
                                          "bg-gray",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered
                                        ]
                                      ], undefined),
                                  style: {
                                    border: "none"
                                  },
                                  onClick: (function (extra) {
                                      return PreVettingQuestionWidget.startInspection(vnode, extra);
                                    })
                                }),
                            preVettingModule: preVettingModule
                          });
              }));
}

var Editor = {
  make: make
};

export {
  Editor ,
  make$1 as make,
}
/* Forms Not a pure module */
