// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Sentry from "../libs/Sentry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Browser from "@sentry/browser";

var apiVersion = "v20250116";

function $$parseInt(value) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, value, (function (v) {
                return Curry._1(Prelude.Int.fromString, v.trim());
              }));
}

function parseBool(value) {
  return Curry._2(Prelude.OptionExported.$$Option.map, value, (function (v) {
                var match = v.toLowerCase().trim();
                switch (match) {
                  case "true" :
                  case "yes" :
                      return true;
                  default:
                    return false;
                }
              }));
}

function parseRate(value) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, Curry._2(Prelude.OptionExported.$$Option.flatMap, value, Prelude.Float.fromString), (function (res) {
                if (0.0 <= res && res <= 1.0) {
                  return res;
                }
                
              }));
}

var res = Prelude.default($$parseInt(import.meta.env.SENTRY_OFFLINE_MAX_EVENTS), 1000);

var maxStoredEvents = res < 100 ? 100 : res;

var SentryOffline = {
  maxStoredEvents: maxStoredEvents
};

var active = Prelude.default(parseBool(import.meta.env.SENTRY_REPLAY), false);

var sessionRate = Prelude.default(parseRate(import.meta.env.SENTRY_REPLAY_SESSION_RATE), 1.0);

var errorRate = Prelude.default(parseRate(import.meta.env.SENTRY_REPLAY_ERROR_RATE), 1.0);

var SentryReplay = {
  active: active,
  sessionRate: sessionRate,
  errorRate: errorRate
};

var apiHost = Prelude.default(import.meta.env.KAIKO_API_HOST, "http://localhost:8000");

var apiBaseUrl = apiHost + "/api/" + apiVersion;

var tag = import.meta.env.KAIKO_RELEASE_TAG;

var releaseVersion = tag !== undefined ? tag : "dev";

var hash = import.meta.env.KAIKO_RELEASE_HASH;

var releaseLocalHash = hash !== undefined ? hash : "?";

var MixPanel = {};

var movementTimeoutInMs = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, $$parseInt(import.meta.env.KAIKO_MOVEMENT_TIMEOUT_IN_MINUTES), (function (minutes) {
            return Math.imul(Math.imul(minutes, 60), 1000);
          })), 1800000);

var useNotifications = Prelude.default(parseBool(import.meta.env.KAIKO_USE_NOTIFICATIONS), false);

var _map = {"development":"development","staging":"staging","canary":"canary","production":"production"};

function sentryEnvironmentToJs(param) {
  return param;
}

function sentryEnvironmentFromJs(param) {
  return _map[param];
}

var sentryDSN = import.meta.env.SENTRY_DSN;

var sentryEnvironment = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, import.meta.env.SENTRY_ENVIRONMENT, sentryEnvironmentFromJs), "development");

var sentryRelease = "kaiko-app@" + releaseVersion;

if (sentryDSN !== undefined) {
  var integrations = active ? [
      Browser.browserTracingIntegration(),
      Browser.replayIntegration(Sentry.Replay.Defaults.val)
    ] : [Browser.browserTracingIntegration()];
  Browser.init({
        dsn: sentryDSN,
        release: sentryRelease,
        environment: sentryEnvironment,
        integrations: integrations,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: sessionRate,
        replaysOnErrorSampleRate: errorRate,
        transport: Browser.makeBrowserOfflineTransport(Browser.makeFetchTransport),
        transportOptions: {
          maxStoredEvents: maxStoredEvents
        }
      });
  Browser.setTag("app.name", "survey-tool");
  Browser.setTag("app.version", releaseVersion);
  Browser.setTag("vessel_name", "");
  Browser.setTag("vessel_id", "");
  Browser.getGlobalScope().addEventProcessor(function ($$event, hint) {
        var exit = 0;
        var val;
        var val$1;
        try {
          val = $$event.type;
          val$1 = hint.originalException.RE_EXN_ID;
          exit = 1;
        }
        catch (exn){
          return $$event;
        }
        if (exit === 1) {
          if (val !== undefined) {
            return $$event;
          }
          if (val$1 === undefined) {
            return $$event;
          }
          var value;
          var exit$1 = 0;
          var msg;
          try {
            msg = hint.originalException.message;
            exit$1 = 2;
          }
          catch (exn$1){
            value = "";
          }
          if (exit$1 === 2) {
            value = msg !== undefined ? msg : "";
          }
          var newrecord = Caml_obj.obj_dup($$event);
          newrecord.extra = {
            hint: JSON.stringify(hint)
          };
          newrecord.exception = {
            values: [{
                type: "RE_EXN_ID: " + val$1,
                value: value
              }]
          };
          return newrecord;
        }
        
      });
}

var tsiModule = Prelude.default(import.meta.env.KAIKO_TSI_MODULE_FEATURE, "forbid");

var newInspectionPlanner = Prelude.default(import.meta.env.KAIKO_NEW_INSPECTION_PLANNER, "forbid");

var FeatureFlags = {
  tsiModule: tsiModule,
  newInspectionPlanner: newInspectionPlanner
};

var attachmentRetries = Prelude.default($$parseInt(import.meta.env.KAIKO_ATTACHMENT_UPLOAD_RETRIES), 3);

var maxPostItems = Prelude.default($$parseInt(import.meta.env.KAIKO_MAX_UPLOAD_POST_ITEMS), 500);

var maxBlobChunkSizeInBytes = (Prelude.default($$parseInt(import.meta.env.KAIKO_MAX_BLOB_CHUNK_KB), 250) << 10);

var Upload = {
  attachmentRetries: attachmentRetries,
  maxPostItems: maxPostItems,
  maxBlobChunkSizeInBytes: maxBlobChunkSizeInBytes
};

var active$1 = parseBool(import.meta.env.KAIKO_DEV_DEBUG);

var active$2 = active$1 !== undefined ? active$1 : Caml_obj.equal(import.meta.env.NODE_ENV, "development");

var skipThumbnails = active$2 && Prelude.default(parseBool(import.meta.env.KAIKO_DEV_SKIP_THUMBNAILS), false);

var automaticReload = active$2 && Prelude.default(parseBool(import.meta.env.KAIKO_DEV_AUTOMATIC_RELOAD), true);

var DeveloperDebug = {
  active: active$2,
  skipThumbnails: skipThumbnails,
  automaticReload: automaticReload
};

var mithrilRoutePrefix = "";

export {
  mithrilRoutePrefix ,
  apiVersion ,
  SentryOffline ,
  SentryReplay ,
  apiHost ,
  apiBaseUrl ,
  releaseVersion ,
  releaseLocalHash ,
  MixPanel ,
  movementTimeoutInMs ,
  useNotifications ,
  sentryEnvironmentToJs ,
  sentryEnvironmentFromJs ,
  sentryDSN ,
  sentryEnvironment ,
  sentryRelease ,
  FeatureFlags ,
  Upload ,
  DeveloperDebug ,
}
/* res Not a pure module */
