// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RisqQuery from "../RisqQuery.js";

function Make(Q) {
  return {
          makeRead: RisqQuery.makeRead,
          makeResponse: RisqQuery.makeResponse,
          makeWrite: RisqQuery.makeWrite,
          read: RisqQuery.read,
          write: RisqQuery.write,
          value: RisqQuery.value,
          $$do: RisqQuery.$$do
        };
}

var ModuleType;

export {
  ModuleType ,
  Make ,
}
/* RisqQuery Not a pure module */
