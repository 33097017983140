// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AppSchema from "../AppSchema.js";
import * as ServerSyncPipeline from "./common/ServerSyncPipeline.js";
import * as ServerSyncDocuments from "./syncsteps/ServerSyncDocuments.js";
import * as ServerSyncRisqLevels from "./syncsteps/risq/ServerSyncRisqLevels.js";
import * as ServerSyncRisqAnswers from "./syncsteps/risq/ServerSyncRisqAnswers.js";
import * as ServerSyncRisqFindings from "./syncsteps/risq/ServerSyncRisqFindings.js";
import * as ServerSyncRisqQuestions from "./syncsteps/risq/ServerSyncRisqQuestions.js";
import * as ServerSyncRisqVettingPeriod from "./syncsteps/risq/ServerSyncRisqVettingPeriod.js";
import * as ServerSyncRisqQuestionsLocations from "./syncsteps/risq/ServerSyncRisqQuestionsLocations.js";

var views = [
  AppSchema.Levels,
  AppSchema.Questions,
  AppSchema.Documents
];

var steps = [
  [
    "Clean up of PreVettingAnswers, Questions and Levels before start getting risq data, because we use the same AppSchema views.",
    ServerSyncPipeline.MakeStandardCleanup({
          views: views
        }).$$do
  ],
  [
    "RISQ: Sync with the vetting periods API endpoint",
    ServerSyncRisqVettingPeriod.$$do
  ],
  [
    "RISQ: Sync with the levels API endpoint",
    ServerSyncRisqLevels.$$do
  ],
  [
    "RISQ: Sync with the questions API endpoint",
    ServerSyncRisqQuestions.$$do
  ],
  [
    "RISQ: Sync with the documents API endpoint",
    ServerSyncDocuments.$$do
  ],
  [
    "RISQ: Sync with the questions locations API endpoint",
    ServerSyncRisqQuestionsLocations.$$do
  ],
  [
    "RISQ: Sync with the answers API endpoint",
    ServerSyncRisqAnswers.$$do
  ],
  [
    "RISQ: Sync with the findings API endpoint",
    ServerSyncRisqFindings.$$do
  ]
];

var include = ServerSyncPipeline.MakeChainPipeline({
      steps: steps
    });

var $$do = include.$$do;

export {
  $$do ,
}
/* include Not a pure module */
