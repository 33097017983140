// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as LevelId from "../tree/LevelId.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as AnswerId from "../tree/AnswerId.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Settings from "../Settings.js";
import * as VesselId from "../vessel/VesselId.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as QuestionId from "../tree/QuestionId.js";
import * as AnswerValue from "../tree/AnswerValue.js";
import * as PreVettingPeriodId from "./PreVettingPeriodId.js";

var Definition = {};

var null_timestamp = new Date();

var null_question_type = {
  NAME: "YesNoQuestion",
  VAL: {
    correct_answer: true
  }
};

var null_evidences = [];

var null_app_version = Settings.releaseVersion;

var $$null = {
  id: AnswerId.$$null,
  level_id: LevelId.$$null,
  vessel_id: VesselId.$$null,
  question_id: QuestionId.$$null,
  timestamp: null_timestamp,
  question_type: null_question_type,
  level_path: "",
  remarks: "",
  walked_steps: 0,
  evidences: null_evidences,
  picture_prompted: false,
  app_version: null_app_version,
  vetting_period_id: PreVettingPeriodId.$$null
};

var Defaults = {
  $$null: $$null
};

var include = ReIndexed.MakeIdModel(Definition, {});

function byTimestamp(answer) {
  return answer.timestamp;
}

function ofVessel(vesselId) {
  return {
          TAG: "Is",
          _0: "vessel_id",
          _1: vesselId
        };
}

function ofQuestion(questionId) {
  return {
          TAG: "Is",
          _0: "question_id",
          _1: questionId
        };
}

function ofQuestions(questionIds) {
  return {
          TAG: "AnyOf",
          _0: "question_id",
          _1: questionIds
        };
}

function insideLevel(path) {
  return {
          TAG: "StartsWith",
          _0: "level_path",
          _1: path
        };
}

function ofLevel(levelId) {
  return {
          TAG: "Is",
          _0: "level_id",
          _1: levelId
        };
}

function lastAnswer(answers) {
  return Prelude.$$Array.last(Prelude.$$Array.sort(answers, byTimestamp, undefined));
}

function make(level_id, level_path, vessel_id, question_id, user_id, profile_id, question_type, value, remarks, walked_steps, evidences, picture_prompted, vetting_period_id) {
  var newrecord = Caml_obj.obj_dup($$null);
  newrecord.vetting_period_id = vetting_period_id;
  newrecord.picture_prompted = picture_prompted;
  newrecord.evidences = evidences;
  newrecord.walked_steps = walked_steps;
  newrecord.remarks = remarks;
  newrecord.level_path = level_path;
  newrecord.question_type = question_type;
  newrecord.timestamp = new Date();
  newrecord.value = value;
  newrecord.profile_id = profile_id;
  newrecord.user_id = user_id;
  newrecord.question_id = question_id;
  newrecord.vessel_id = vessel_id;
  newrecord.level_id = level_id;
  newrecord.id = AnswerId.make();
  return newrecord;
}

function byTimestamp$1(answer) {
  return answer.timestamp;
}

function byQuestion(answer) {
  return answer.question_id;
}

function ids(__x) {
  return __x.map(function (answer) {
              return answer.id;
            });
}

function getEvidences(answer) {
  var attachments = answer.evidences;
  var match = answer.value;
  if (match === undefined) {
    return attachments;
  }
  if (typeof match !== "object") {
    return attachments;
  }
  var match$1 = match.VAL;
  var variant = match$1.NAME;
  if (variant === "Pictures") {
    return Curry._2(Prelude.$$Array.concat, attachments, match$1.VAL);
  } else if (variant === "Signature") {
    return Prelude.$$Array.append(attachments, match$1.VAL);
  } else {
    return attachments;
  }
}

function _getImages(wrapper, answers) {
  return Prelude.$$Array.flatMap(answers, getEvidences).map(function (param) {
              return wrapper({
                          id: param.id,
                          file: param.file,
                          thumbnail: param.thumbnail
                        });
            });
}

function getImages(extra) {
  return _getImages((function (i) {
                return {
                        TAG: "Cloud",
                        _0: i
                      };
              }), extra);
}

function questionVisibilityGetter(question) {
  var rank = function (profile) {
    return Curry._2(Prelude.OptionExported.$$Option.map, profile, (function (p) {
                  return p.rank;
                }));
  };
  var match = question.answer_visibility;
  if (match === "AllAnswers") {
    return function (param, param$1) {
      return true;
    };
  } else if (match === "OnlyUserAnswers") {
    return function (currentProfile, answerProfile) {
      if (currentProfile !== undefined && answerProfile !== undefined) {
        return Caml_obj.equal(currentProfile.id, answerProfile.id);
      } else {
        return false;
      }
    };
  } else if (match === "NoAnswers") {
    return function (param, param$1) {
      return false;
    };
  } else {
    return function (currentProfile, answerProfile) {
      return Caml_obj.equal(rank(currentProfile), rank(answerProfile));
    };
  }
}

var Identifier;

var Value;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Value ,
  Definition ,
  Defaults ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
  ofVessel ,
  ofQuestion ,
  ofQuestions ,
  insideLevel ,
  ofLevel ,
  lastAnswer ,
  make ,
  byTimestamp$1 as byTimestamp,
  byQuestion ,
  ids ,
  getEvidences ,
  _getImages ,
  getImages ,
  questionVisibilityGetter ,
}
/* null Not a pure module */
