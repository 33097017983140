// Generated by ReScript, PLEASE EDIT WITH CARE

import * as InitFeedback from "../../InitFeedback.js";
import * as IDB__Migration__Database from "@kaiko.io/rescript-reindexed/lib/es6/src/IDB/Migration/IDB__Migration__Database.js";

function message() {
  return "Creating risk Answers store";
}

async function migrate(db, _transaction) {
  IDB__Migration__Database.createStandardStore(db, "answers", [
        "vessel_id",
        "question_id",
        "level_id",
        "level_path",
        "vetting_period_id"
      ]);
  IDB__Migration__Database.createStandardStore(db, "newAnswers", [
        "vessel_id",
        "question_id",
        "level_id",
        "level_path",
        "vetting_period_id"
      ]);
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var include = InitFeedback.MakeMigration({
      message: message,
      migrate: migrate
    });

var make = include.make;

export {
  make ,
}
/* include Not a pure module */
