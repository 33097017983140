// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../../common/Forms.js";
import * as Query from "../../../Query.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Vessel from "../../../vessel/Vessel.js";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as LocalImage from "../../../../libs/LocalImage.js";
import * as QuestionId from "../../../tree/QuestionId.js";
import * as Hyphenated from "hyphenated";
import * as AnswerValue from "../../../tree/AnswerValue.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Conditional from "../../../../libs/Conditional.js";
import * as TakePicture from "../../../common/views/TakePicture.js";
import MarkdownIt from "markdown-it";
import * as DetailImages from "../../../common/views/DetailImages.js";
import * as DialogNewEdit from "../../../../libs/DialogNewEdit.js";
import * as KeepScrollLink from "../../../common/views/KeepScrollLink.js";
import * as Mithril__Route from "../../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as NavigationPills from "../../../common/views/NavigationPills.js";
import * as ConditionMatcher from "../../../common/ConditionMatcher.js";
import * as MarkdownDocumentId from "../../../markdown/MarkdownDocumentId.js";
import * as DialogNewEditAnswer from "../../../tree/views/questions/DialogNewEditAnswer.js";
import * as MarkdownDocumentPage from "../../../markdown/views/MarkdownDocumentPage.js";
import * as PreVettingQuestionWidget from "./PreVettingQuestionWidget.js";
import * as State__PreVettingAnswers from "../../../state/State__PreVettingAnswers.js";

function processAttachment(vnode, _image, attachment, param) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return ;
  } else {
    return Mithril.setState(vnode, {
                TAG: "Loaded",
                vessel: match.vessel,
                lastNewAnswer: match.lastNewAnswer,
                lastAnswer: match.lastAnswer,
                question: match.question,
                document: match.document,
                level: match.level,
                value: match.value,
                step: "reviewPicture",
                remarks: match.remarks,
                lastRemark: match.lastRemark,
                pictures: Curry._2(Prelude.$$Array.concat, match.pictures, [attachment]),
                annotations: match.annotations,
                picture_prompted: match.picture_prompted
              });
  }
}

function allowSave(vnode, param) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return false;
  }
  var value = match.value;
  if (value) {
    return true;
  } else if (value) {
    return false;
  } else {
    return match.remarks !== "";
  }
}

function getValue(vnode, param) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return {
            attachments: []
          };
  } else {
    return {
            value: {
              NAME: "Value",
              VAL: {
                NAME: "OKNotOK",
                VAL: [
                  match.value,
                  match.annotations
                ]
              }
            },
            attachments: match.pictures,
            remarks: match.remarks
          };
  }
}

async function updateState(vnode, questionState) {
  var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
  var value = Prelude.default(Mithril__Route.paramBool("value"), true);
  if (typeof questionState !== "object") {
    return Mithril.setState(vnode, "NotReady");
  }
  var vessel = questionState.vessel;
  var level = questionState.level;
  var question = questionState.question;
  var lastNewAnswer = questionState.lastNewAnswer;
  var lastAnswer = questionState.lastAnswer;
  var lastValue = lastNewAnswer !== undefined ? Caml_option.some(lastNewAnswer.value) : (
      lastAnswer !== undefined ? Caml_option.some(lastAnswer.value) : undefined
    );
  return Prelude.thenDo(ConditionMatcher.Question.match(question, {
                  NAME: "Value",
                  VAL: {
                    NAME: "OKNotOK",
                    VAL: [
                      value,
                      []
                    ]
                  }
                }, lastValue), (function (param) {
                var picture_prompted = param[1];
                var require_picture = param[0];
                var record = vnode.state.data;
                if (typeof record === "object") {
                  return Mithril.setState(vnode, {
                              TAG: "Loaded",
                              vessel: vessel,
                              lastNewAnswer: lastNewAnswer,
                              lastAnswer: lastAnswer,
                              question: question,
                              document: record.document,
                              level: level,
                              value: record.value,
                              step: record.step,
                              remarks: record.remarks,
                              lastRemark: record.lastRemark,
                              pictures: record.pictures,
                              annotations: record.annotations,
                              picture_prompted: picture_prompted
                            });
                }
                var newrecord = Caml_obj.obj_dup(Query.makeRead());
                Prelude.thenDo(Query.read((newrecord.documents = {
                            TAG: "Get",
                            _0: Prelude.default(question.guidance_document_id, MarkdownDocumentId.$$null)
                          }, newrecord)), (function (param) {
                        var $$document = Prelude.$$Array.first(param.documents);
                        var match;
                        if (createNew) {
                          match = [
                            [],
                            "",
                            value,
                            []
                          ];
                        } else if (lastNewAnswer !== undefined) {
                          var match$1 = lastNewAnswer.value;
                          var exit = 0;
                          if (match$1 !== undefined && typeof match$1 === "object" && match$1.NAME === "Value") {
                            var match$2 = match$1.VAL;
                            if (typeof match$2 === "object" && match$2.NAME === "OKNotOK") {
                              match = [
                                lastNewAnswer.evidences,
                                lastNewAnswer.remarks,
                                value,
                                match$2.VAL[1]
                              ];
                            } else {
                              exit = 1;
                            }
                          } else {
                            exit = 1;
                          }
                          if (exit === 1) {
                            match = [
                              [],
                              "",
                              value,
                              []
                            ];
                          }
                          
                        } else {
                          match = [
                            [],
                            "",
                            value,
                            []
                          ];
                        }
                        var lastRemark = createNew ? Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.orElse, lastNewAnswer, lastAnswer), (function (a) {
                                      return a.remarks;
                                    })), "") : "";
                        var step = require_picture ? "takePicture" : "evalAnswer";
                        Mithril.setState(vnode, {
                              TAG: "Loaded",
                              vessel: vessel,
                              lastNewAnswer: lastNewAnswer,
                              lastAnswer: lastAnswer,
                              question: question,
                              document: $$document,
                              level: level,
                              value: match[2],
                              step: step,
                              remarks: match[1],
                              lastRemark: lastRemark,
                              pictures: match[0],
                              annotations: match[3],
                              picture_prompted: picture_prompted
                            });
                      }));
              }));
}

function PreVettingOKNotOKQuestionWidget$Editor(props) {
  return Mithril.view(Mithril.onupdate(Mithril.oncreate(Mithril.component("NotReady"), Forms.Input.focus), Forms.Input.focus), (function (vnode) {
                var isRectification = Prelude.default(Mithril__Route.paramBool("isRectification"), false);
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var picture_prompted = match.picture_prompted;
                  var annotations = match.annotations;
                  var pictures = match.pictures;
                  var lastRemark = match.lastRemark;
                  var remarks = match.remarks;
                  var step = match.step;
                  var value = match.value;
                  var level = match.level;
                  var $$document = match.document;
                  var question = match.question;
                  var lastAnswer = match.lastAnswer;
                  var lastNewAnswer = match.lastNewAnswer;
                  var vessel = match.vessel;
                  if (step === "reviewPicture") {
                    var attachment = Prelude.$$Array.first(pictures);
                    tmp = [
                      React.jsx(NavigationPills.make, {
                            currentStep: 1,
                            stepCount: 2
                          }),
                      ReactDOM.jsx("p", {
                            children: question.name
                          }),
                      attachment !== undefined ? React.jsx(LocalImage.Resource.make, {
                              resource: attachment.id
                            }) : Mithril.empty,
                      ReactDOM.jsx("div", {
                            className: "divider xl blank"
                          }),
                      React.jsx(DetailImages.make, {
                            deleteAttachment: (async function (v1, param) {
                                console.log(v1);
                              }),
                            attachments: Curry._2(Prelude.$$Array.sliceToEnd, pictures, 1)
                          })
                    ];
                  } else if (step === "evalAnswer") {
                    var match$1 = question.kind;
                    var annotationOptions = typeof match$1 === "object" ? (
                        match$1.NAME === "OKNotOKQuestion" ? match$1.VAL.annotations.map(function (annotation) {
                                return ReactDOM.jsxKeyed("div", {
                                            children: ReactDOM.jsxs("label", {
                                                  children: [
                                                    ReactDOM.jsx("input", {
                                                          checked: Prelude.$$Array.includes(annotations, annotation),
                                                          type: "checkbox",
                                                          onChange: (function ($$event) {
                                                              var state = vnode.state.data;
                                                              if (typeof state !== "object") {
                                                                return ;
                                                              }
                                                              var newAnnotations = Prelude.$$Array.includes(state.annotations, annotation) ? Curry._2(Prelude.$$Array.keep, state.annotations, (function (a) {
                                                                        return a !== annotation;
                                                                      })) : Curry._2(Prelude.$$Array.concat, state.annotations, [annotation]);
                                                              Mithril.setState(vnode, {
                                                                    TAG: "Loaded",
                                                                    vessel: state.vessel,
                                                                    lastNewAnswer: state.lastNewAnswer,
                                                                    lastAnswer: state.lastAnswer,
                                                                    question: state.question,
                                                                    document: state.document,
                                                                    level: state.level,
                                                                    value: state.value,
                                                                    step: state.step,
                                                                    remarks: state.remarks,
                                                                    lastRemark: state.lastRemark,
                                                                    pictures: state.pictures,
                                                                    annotations: newAnnotations,
                                                                    picture_prompted: state.picture_prompted
                                                                  });
                                                            })
                                                        }),
                                                    ReactDOM.jsx("i", {
                                                          className: "form-icon"
                                                        }),
                                                    annotation
                                                  ],
                                                  className: "form-checkbox"
                                                }),
                                            className: "form-group"
                                          }, annotation, undefined);
                              }) : []
                      ) : [];
                    tmp = [ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxs("div", {
                                    children: [
                                      ReactDOM.jsx("div", {
                                            children: question.name,
                                            className: "tile-content text-small"
                                          }),
                                      ReactDOM.jsx("br", {}),
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              ReactDOM.jsx("div", {
                                                    children: ReactDOM.jsxs("button", {
                                                          children: [
                                                            React.jsx(Feather.make, {
                                                                  icon: "check"
                                                                }),
                                                            "OK"
                                                          ],
                                                          className: "btn full-width " + (
                                                            value ? "btn-success" : ""
                                                          ),
                                                          onClick: (function (param) {
                                                              Mithril.setState(vnode, {
                                                                    TAG: "Loaded",
                                                                    vessel: vessel,
                                                                    lastNewAnswer: lastNewAnswer,
                                                                    lastAnswer: lastAnswer,
                                                                    question: question,
                                                                    document: $$document,
                                                                    level: level,
                                                                    value: true,
                                                                    step: step,
                                                                    remarks: remarks,
                                                                    lastRemark: lastRemark,
                                                                    pictures: pictures,
                                                                    annotations: annotations,
                                                                    picture_prompted: picture_prompted
                                                                  });
                                                            })
                                                        }),
                                                    className: "column col-6"
                                                  }),
                                              ReactDOM.jsx("div", {
                                                    children: ReactDOM.jsxs("button", {
                                                          children: [
                                                            React.jsx(Feather.make, {
                                                                  icon: "x"
                                                                }),
                                                            "Not OK"
                                                          ],
                                                          className: "btn full-width " + (
                                                            value ? "" : "btn-error"
                                                          ),
                                                          onClick: (function (param) {
                                                              Mithril.setState(vnode, {
                                                                    TAG: "Loaded",
                                                                    vessel: vessel,
                                                                    lastNewAnswer: lastNewAnswer,
                                                                    lastAnswer: lastAnswer,
                                                                    question: question,
                                                                    document: $$document,
                                                                    level: level,
                                                                    value: false,
                                                                    step: step,
                                                                    remarks: remarks,
                                                                    lastRemark: lastRemark,
                                                                    pictures: pictures,
                                                                    annotations: annotations,
                                                                    picture_prompted: picture_prompted
                                                                  });
                                                            })
                                                        }),
                                                    className: "column col-6"
                                                  })
                                            ],
                                            className: "columns"
                                          })
                                    ],
                                    className: "text-center"
                                  }),
                              React.jsxs(Conditional.make, {
                                    cond: !value || value && !vessel.hide_observations && !isRectification,
                                    children: [
                                      React.jsx(Conditional.make, {
                                            cond: !Prelude.$$Array.isEmpty(annotations),
                                            children: ReactDOM.jsxs("div", {
                                                  children: [
                                                    ReactDOM.jsx("div", {
                                                          className: "divider blank xl"
                                                        }),
                                                    ReactDOM.jsx("div", {
                                                          children: annotationOptions,
                                                          className: "form-group"
                                                        })
                                                  ]
                                                })
                                          }),
                                      ReactDOM.jsx("div", {
                                            className: "divider blank xl"
                                          }),
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              ReactDOM.jsx("label", {
                                                    children: "Remarks",
                                                    className: "form-label text-small text-bold",
                                                    htmlFor: question.id
                                                  }),
                                              ReactDOM.jsx("textarea", {
                                                    className: "form-input",
                                                    id: question.id,
                                                    style: {
                                                      height: "60%",
                                                      width: "100%"
                                                    },
                                                    name: question.id,
                                                    required: !value,
                                                    rows: 10,
                                                    onKeyUp: (function ($$event) {
                                                        Mithril.setState(vnode, {
                                                              TAG: "Loaded",
                                                              vessel: vessel,
                                                              lastNewAnswer: lastNewAnswer,
                                                              lastAnswer: lastAnswer,
                                                              question: question,
                                                              document: $$document,
                                                              level: level,
                                                              value: value,
                                                              step: step,
                                                              remarks: $$event.target.value,
                                                              lastRemark: lastRemark,
                                                              pictures: pictures,
                                                              annotations: annotations,
                                                              picture_prompted: picture_prompted
                                                            });
                                                      })
                                                  }),
                                              ReactDOM.jsx("p", {
                                                    children: "If you answer 'Not OK' the remarks field is mandatory",
                                                    className: "text-tiny text-gray text-center"
                                                  }),
                                              ReactDOM.jsx("div", {
                                                    className: "divider blank"
                                                  }),
                                              lastRemark !== "" ? ReactDOM.jsxs("div", {
                                                      children: [
                                                        ReactDOM.jsx("strong", {
                                                              children: "Previous remarks:",
                                                              className: "text-tiny mr-2"
                                                            }),
                                                        ReactDOM.jsx("p", {
                                                              children: lastRemark,
                                                              className: "text-tiny text-gray text-italic"
                                                            })
                                                      ]
                                                    }) : Mithril.empty
                                            ],
                                            className: "form-group"
                                          })
                                    ]
                                  })
                            ]
                          })];
                  } else {
                    var last = Curry._2(Prelude.OptionExported.$$Option.orElse, lastNewAnswer, lastAnswer);
                    var tmp$1;
                    if (last !== undefined) {
                      var attachment$1 = Prelude.$$Array.first(last.evidences);
                      tmp$1 = ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsx("h4", {
                                    children: "Example",
                                    className: "text-center"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank"
                                  }),
                              ReactDOM.jsxs("figure", {
                                    children: [
                                      attachment$1 !== undefined ? React.jsx(LocalImage.Resource.make, {
                                              resource: attachment$1.id
                                            }) : Mithril.empty,
                                      ReactDOM.jsx("div", {
                                            className: "divider blank xl"
                                          }),
                                      ReactDOM.jsx("figcaption", {
                                            children: "The photo should look like this",
                                            className: "figure-caption text-center"
                                          })
                                    ],
                                    className: "figure"
                                  }),
                              ReactDOM.jsx("div", {
                                    className: "divider blank xl"
                                  })
                            ]
                          });
                    } else {
                      tmp$1 = Mithril.empty;
                    }
                    tmp = [
                      React.jsx(NavigationPills.make, {
                            currentStep: 0,
                            stepCount: 2
                          }),
                      $$document !== undefined ? React.jsx(MarkdownDocumentPage.MarkdownDocumentView.make, {
                              document: $$document
                            }) : Mithril.empty,
                      tmp$1
                    ];
                  }
                }
                return React.jsx(PreVettingQuestionWidget.Editor.make, {
                            getValue: (function (extra) {
                                return getValue(vnode, extra);
                              }),
                            isDirty: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var pictures = match.pictures;
                                var remarks = match.remarks;
                                if (createNew) {
                                  if (Caml_obj.notequal(pictures, [])) {
                                    return true;
                                  } else {
                                    return remarks !== "";
                                  }
                                }
                                var lastNewAnswer = match.lastNewAnswer;
                                if (lastNewAnswer === undefined) {
                                  return false;
                                }
                                var match$1 = lastNewAnswer.value;
                                if (match$1 === undefined) {
                                  return false;
                                }
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "Value") {
                                  return false;
                                }
                                var match$2 = match$1.VAL;
                                if (typeof match$2 !== "object") {
                                  return false;
                                }
                                if (match$2.NAME !== "OKNotOK") {
                                  return false;
                                }
                                var match$3 = match$2.VAL;
                                if (Caml_obj.notequal(pictures, lastNewAnswer.evidences) || match.value !== match$3[0] || remarks !== lastNewAnswer.remarks) {
                                  return true;
                                } else {
                                  return Caml_obj.notequal(Prelude.$$Array.sort(match.annotations, (function (a) {
                                                    return a;
                                                  }), undefined), Prelude.$$Array.sort(match$3[1], (function (a) {
                                                    return a;
                                                  }), undefined));
                                }
                              }),
                            updateChildrenState: (function (extra) {
                                return updateState(vnode, extra);
                              }),
                            bottom: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return Mithril.empty;
                                }
                                var picture_prompted = match.picture_prompted;
                                var step = match.step;
                                var question = match.question;
                                var vessel = match.vessel;
                                if (step === "reviewPicture") {
                                  return ReactDOM.jsxs("div", {
                                              children: [
                                                React.jsxs(TakePicture.make, {
                                                      role: "EVIDENCE",
                                                      requireLandscape: question.require_landscape_pictures,
                                                      style: {
                                                        height: "3rem",
                                                        marginRight: "0.66666667%",
                                                        width: "66%"
                                                      },
                                                      children: [
                                                        React.jsx(Feather.make, {
                                                              icon: "camera"
                                                            }),
                                                        "Add detail pictures"
                                                      ],
                                                      onCapture: (function (extra, extra$1, extra$2) {
                                                          return processAttachment(vnode, extra, extra$1, extra$2);
                                                        }),
                                                      useEmbeddedCamera: Vessel.getCameraConf(vessel),
                                                      resolution: vessel.max_pic_resolution
                                                    }),
                                                ReactDOM.jsxs("button", {
                                                      children: [
                                                        React.jsx(Feather.make, {
                                                              icon: "save"
                                                            }),
                                                        "Continue"
                                                      ],
                                                      className: "btn btn-primary col-4",
                                                      style: {
                                                        height: "3rem"
                                                      },
                                                      onClick: (function (extra) {
                                                          var match = vnode.state.data;
                                                          if (typeof match !== "object") {
                                                            return ;
                                                          } else {
                                                            return Mithril.setState(vnode, {
                                                                        TAG: "Loaded",
                                                                        vessel: match.vessel,
                                                                        lastNewAnswer: match.lastNewAnswer,
                                                                        lastAnswer: match.lastAnswer,
                                                                        question: match.question,
                                                                        document: match.document,
                                                                        level: match.level,
                                                                        value: match.value,
                                                                        step: "evalAnswer",
                                                                        remarks: match.remarks,
                                                                        lastRemark: match.lastRemark,
                                                                        pictures: match.pictures,
                                                                        annotations: match.annotations,
                                                                        picture_prompted: match.picture_prompted
                                                                      });
                                                          }
                                                        })
                                                    })
                                              ],
                                              className: "columns m-0"
                                            });
                                } else if (step === "evalAnswer") {
                                  return ReactDOM.jsx("button", {
                                              children: "Save",
                                              className: Mithril.$$class([
                                                    [
                                                      "btn btn-primary btn-block btn-lg ",
                                                      true
                                                    ],
                                                    [
                                                      "disabled",
                                                      !allowSave(vnode, undefined)
                                                    ]
                                                  ], undefined),
                                              onClick: (function (extra) {
                                                  return PreVettingQuestionWidget.Editor.save(vnode, (function (extra) {
                                                                return getValue(vnode, extra);
                                                              }), picture_prompted, extra);
                                                })
                                            });
                                } else {
                                  return React.jsxs(TakePicture.make, {
                                              role: "MAIN_EVIDENCE",
                                              requireLandscape: question.require_landscape_pictures,
                                              className: "btn btn-primary btn-block btn-lg t-continue",
                                              children: [
                                                "Take picture",
                                                React.jsx(Feather.make, {
                                                      icon: "camera",
                                                      className: "ml-2"
                                                    })
                                              ],
                                              onCapture: (function (extra, extra$1, extra$2) {
                                                  return processAttachment(vnode, extra, extra$1, extra$2);
                                                }),
                                              onCameraFailure: (function (extra) {
                                                  console.log(vnode);
                                                }),
                                              useEmbeddedCamera: Vessel.getCameraConf(vessel),
                                              resolution: vessel.max_pic_resolution
                                            });
                                }
                              }),
                            children: tmp
                          });
              }));
}

function okButtonClasses(_vnode, lastNewAnswer, lastAnswer) {
  var newAnswerValue = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastNewAnswer, (function (a) {
              return a.value;
            })), undefined);
  var answerValue = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastAnswer, (function (a) {
              return a.value;
            })), undefined);
  if (newAnswerValue !== undefined) {
    if (typeof newAnswerValue !== "object") {
      return "";
    }
    if (newAnswerValue.NAME !== "Value") {
      return "";
    }
    var match = newAnswerValue.VAL;
    if (typeof match === "object" && match.NAME === "OKNotOK" && match.VAL[0]) {
      return "active";
    } else {
      return "";
    }
  }
  if (answerValue === undefined) {
    return "";
  }
  if (typeof answerValue !== "object") {
    return "";
  }
  if (answerValue.NAME !== "Value") {
    return "";
  }
  var match$1 = answerValue.VAL;
  if (typeof match$1 === "object" && match$1.NAME === "OKNotOK" && match$1.VAL[0]) {
    return "bg-gray";
  } else {
    return "";
  }
}

function notOkButtonClasses(_vnode, lastNewAnswer, lastAnswer) {
  var newAnswerValue = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastNewAnswer, (function (a) {
              return a.value;
            })), undefined);
  var answerValue = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, lastAnswer, (function (a) {
              return a.value;
            })), undefined);
  if (newAnswerValue !== undefined) {
    if (typeof newAnswerValue !== "object") {
      return "";
    }
    if (newAnswerValue.NAME !== "Value") {
      return "";
    }
    var match = newAnswerValue.VAL;
    if (typeof match === "object" && match.NAME === "OKNotOK" && !match.VAL[0]) {
      return "active";
    } else {
      return "";
    }
  }
  if (answerValue === undefined) {
    return "";
  }
  if (typeof answerValue !== "object") {
    return "";
  }
  if (answerValue.NAME !== "Value") {
    return "";
  }
  var match$1 = answerValue.VAL;
  if (typeof match$1 === "object" && match$1.NAME === "OKNotOK" && !match$1.VAL[0]) {
    return "bg-gray";
  } else {
    return "";
  }
}

function onDialogCallback(vnode, value, action) {
  var vessel = vnode.attrs.vessel;
  var levelId = vnode.attrs.level.id;
  var question = vnode.attrs.question;
  var preVettingModule = vnode.attrs.preVettingModule;
  Mithril$1.route.set(URLs.preVettingOkNotOkQuestionWidgetEditor(action !== "Edit", undefined, Caml_option.some(value), undefined, vessel.id, levelId, question.id, preVettingModule));
}

function startInspection(vnode, dialogId, value, isAlreadyAnswered, _event) {
  KeepScrollLink.saveScroll();
  var preVettingModule = vnode.attrs.preVettingModule;
  var state = vnode.state.data;
  vnode.state.data = {
    isBusy: true,
    currentAnswer: state.currentAnswer
  };
  var answer = vnode.attrs.lastNewAnswer;
  var lastValue;
  if (answer !== undefined) {
    lastValue = Caml_option.some(answer.value);
  } else {
    var answer$1 = vnode.attrs.lastAnswer;
    lastValue = answer$1 !== undefined ? Caml_option.some(answer$1.value) : undefined;
  }
  Prelude.thenDo(ConditionMatcher.Question.match(vnode.attrs.question, {
            NAME: "Value",
            VAL: {
              NAME: "OKNotOK",
              VAL: [
                value,
                []
              ]
            }
          }, lastValue), (function (param) {
          if (isAlreadyAnswered) {
            vnode.state.data = {
              isBusy: false,
              currentAnswer: state.currentAnswer
            };
            return DialogNewEdit.openDialog(vnode, dialogId);
          } else if (!param[0] && value) {
            var closeDialogOpt;
            var picture_prompted = param[1];
            var closeDialog = closeDialogOpt !== undefined ? Caml_option.valFromOption(closeDialogOpt) : undefined;
            var vessel = vnode.attrs.vessel;
            var level = vnode.attrs.level;
            var question = vnode.attrs.question;
            var preVettingModule$1 = vnode.attrs.preVettingModule;
            var value$1 = {
              NAME: "Value",
              VAL: {
                NAME: "OKNotOK",
                VAL: [
                  value,
                  []
                ]
              }
            };
            return Prelude.thenDo(State__PreVettingAnswers.make(vessel.id, level.id, level.path, question.id, question.kind, value$1, "", [], picture_prompted, preVettingModule$1), (function (param) {
                          Mithril.setState(vnode, {
                                isBusy: false,
                                currentAnswer: Prelude.$$Array.first(param.newAnswers)
                              });
                          if (Caml_obj.equal(closeDialog, true)) {
                            window.history.back();
                            return ;
                          }
                          
                        }));
          } else {
            Mithril$1.route.set(URLs.preVettingOkNotOkQuestionWidgetEditor(undefined, undefined, Caml_option.some(value), undefined, vnode.attrs.vessel.id, vnode.attrs.level.id, vnode.attrs.question.id, preVettingModule));
            return ;
          }
        }));
}

function make(param) {
  return Mithril.view(Mithril.oninit(Mithril.component({
                      isBusy: false,
                      currentAnswer: undefined
                    }), (function (vnode) {
                    var state = vnode.state.data;
                    Mithril.setState(vnode, {
                          isBusy: state.isBusy,
                          currentAnswer: vnode.attrs.lastNewAnswer
                        });
                  })), (function (vnode) {
                var question = vnode.attrs.question;
                var questionId = question.id;
                var lastAnswer = vnode.attrs.lastAnswer;
                var namePrefix = Prelude.default(vnode.attrs.namePrefix, "");
                var questionTitle = new MarkdownIt().render(namePrefix + " " + Hyphenated.hyphenated(vnode.attrs.question.name));
                var match = vnode.state.data;
                var currentAnswer = match.currentAnswer;
                var isBusy = match.isBusy;
                var isAlreadyAnswered = Curry._1(Prelude.OptionExported.$$Option.isSome, currentAnswer);
                var match$1 = question.area;
                return ReactDOM.jsxs("div", {
                            children: [
                              ReactDOM.jsxKeyed("div", {
                                    children: [
                                      match$1 !== undefined ? (
                                          match$1 === "Hardware" ? React.jsx(Feather.make, {
                                                  icon: "hardware",
                                                  className: "question-icon col-1",
                                                  size: 24
                                                }) : (
                                              match$1 === "Process" ? React.jsx(Feather.make, {
                                                      icon: "process",
                                                      className: "question-icon col-1",
                                                      size: 24
                                                    }) : React.jsx(Feather.make, {
                                                      icon: "hr",
                                                      className: "question-icon col-1",
                                                      size: 24
                                                    })
                                            )
                                        ) : Mithril.empty,
                                      ReactDOM.jsx("div", {
                                            children: Mithril$1.trust(questionTitle),
                                            className: "col-11 col-sm-12 px-1"
                                          })
                                    ],
                                    className: "text-small question-name col col-10 col-sm-12 d-flex"
                                  }, QuestionId.toHTMLID(question.id) + "title", undefined),
                              ReactDOM.jsxsKeyed("div", {
                                    children: [
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              React.jsx(Conditional.make, {
                                                    cond: isAlreadyAnswered,
                                                    children: React.jsx(DialogNewEditAnswer.make, {
                                                          id: questionId + "ok",
                                                          onCallback: (function (extra) {
                                                              return onDialogCallback(vnode, true, extra);
                                                            })
                                                        })
                                                  }),
                                              ReactDOM.jsx("button", {
                                                    children: React.jsx(Feather.make, {
                                                          icon: "check"
                                                        }),
                                                    className: Mithril.$$class([
                                                          [
                                                            "t-yes-answer btn btn-action s-circle text-tiny " + okButtonClasses(vnode, currentAnswer, lastAnswer),
                                                            true
                                                          ],
                                                          [
                                                            "disabled loading",
                                                            isBusy
                                                          ]
                                                        ], undefined),
                                                    onClick: (function (extra) {
                                                        return startInspection(vnode, questionId + "ok", true, isAlreadyAnswered, extra);
                                                      })
                                                  })
                                            ],
                                            className: "mr-1"
                                          }),
                                      ReactDOM.jsxs("div", {
                                            children: [
                                              React.jsx(Conditional.make, {
                                                    cond: isAlreadyAnswered,
                                                    children: React.jsx(DialogNewEditAnswer.make, {
                                                          id: questionId + "notOk",
                                                          onCallback: (function (extra) {
                                                              return onDialogCallback(vnode, false, extra);
                                                            })
                                                        })
                                                  }),
                                              ReactDOM.jsx("button", {
                                                    children: React.jsx(Feather.make, {
                                                          icon: "x"
                                                        }),
                                                    className: Mithril.$$class([
                                                          [
                                                            "t-no-answer btn btn-action s-circle text-tiny " + notOkButtonClasses(vnode, currentAnswer, lastAnswer),
                                                            true
                                                          ],
                                                          [
                                                            "disabled loading",
                                                            isBusy
                                                          ]
                                                        ], undefined),
                                                    onClick: (function (extra) {
                                                        return startInspection(vnode, questionId + "notOk", false, isAlreadyAnswered, extra);
                                                      })
                                                  })
                                            ]
                                          })
                                    ],
                                    className: "tile-action question-answers col col-2 col-sm-12"
                                  }, QuestionId.toHTMLID(question.id) + "widget", undefined)
                            ],
                            className: "tw-rounded tw-shadow-kaiko-card columns tile tile-shadow tile-centered question t-question p-2 my-2 mx-0"
                          });
              }));
}

var Editor = {
  make: PreVettingOKNotOKQuestionWidget$Editor
};

export {
  Editor ,
  make ,
}
/* URLs Not a pure module */
