// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as AppSchema from "../../../AppSchema.js";
import * as RisqQuery from "../../../RisqQuery.js";
import * as RisqLevelsAPI from "../../endpoints/RisqLevelsAPI.js";
import * as ServerSyncLevels from "../ServerSyncLevels.js";
import * as ServerSyncPipeline from "../../common/ServerSyncPipeline.js";

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  console.log("ServerSyncRisqLevels", "ReadServerState", {
        initial: initial,
        final: $$final
      });
  return Prelude.PromisedResult.map(RisqLevelsAPI.getAll(undefined, {
                  vessel__id__in: vessels.join(",")
                }, undefined, user), (function (levels) {
                var $$final$1 = ServerSyncLevels.ReadServerState.convertLevels($$final, levels);
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final$1
                      };
              }));
}

var ReadServerState = {
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  console.log("ServerSyncRisqLevels", "FillInitialStatus", {
        initial: initial,
        final: $$final
      });
  var init = RisqQuery.makeRead();
  return Prelude.PromisedResult.map(RisqQuery.Safe.read({
                  levels: {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  },
                  answers: init.answers,
                  newAnswers: init.newAnswers,
                  risqVettingPeriods: init.risqVettingPeriods,
                  questions: init.questions,
                  findings: init.findings,
                  risqQuestionLocations: init.risqQuestionLocations
                }), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.levels, (function (extra) {
                        return AppSchema.Levels.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var init = RisqQuery.makeWrite();
  var actions_levels = AppSchema.Levels.getActions(initial, $$final);
  var actions_answers = init.answers;
  var actions_newAnswers = init.newAnswers;
  var actions_risqVettingPeriods = init.risqVettingPeriods;
  var actions_questions = init.questions;
  var actions_findings = init.findings;
  var actions_risqQuestionLocations = init.risqQuestionLocations;
  var actions = {
    levels: actions_levels,
    answers: actions_answers,
    newAnswers: actions_newAnswers,
    risqVettingPeriods: actions_risqVettingPeriods,
    questions: actions_questions,
    findings: actions_findings,
    risqQuestionLocations: actions_risqQuestionLocations
  };
  RisqQuery.ActionLogging.info("ServerSyncRisqLevels" + ": db actions: ", actions);
  return Prelude.PromisedResult.map(RisqQuery.Safe.write(actions), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [
  AppSchema.Levels,
  AppSchema.LevelSubmissions
];

var CollectFiles = ServerSyncPipeline.MakeFilesCollector({
      views: views
    });

var CleanUpState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncRisqLevels",
      ReadServerState: ReadServerState,
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var Query;

var $$do$3 = include.$$do;

export {
  Query ,
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */
