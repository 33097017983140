// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../../common/Forms.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Mithril__Route from "../../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as DateQuestionUtils from "../../../tree/views/questions/DateQuestionUtils.js";
import * as PreVettingQuestionWidget from "./PreVettingQuestionWidget.js";

async function updateState(vnode, questionState) {
  var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
  var newState;
  if (typeof questionState !== "object") {
    newState = "NotReady";
  } else {
    var lastNewAnswer = questionState.lastNewAnswer;
    var match = vnode.state.data;
    var match$1;
    if (typeof match !== "object") {
      if (createNew) {
        match$1 = [
          [],
          []
        ];
      } else if (lastNewAnswer !== undefined) {
        var match$2 = lastNewAnswer.value;
        if (match$2 !== undefined) {
          if (typeof match$2 === "object") {
            if (match$2.NAME === "Value") {
              var match$3 = match$2.VAL;
              if (typeof match$3 === "object") {
                if (match$3.NAME === "MultipleDatesTexts") {
                  var match$4 = match$3.VAL;
                  match$1 = [
                    match$4[0].map(function (d) {
                          return [
                                  Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, d, DateQuestionUtils.formatDate), ""),
                                  d
                                ];
                        }),
                    match$4[1]
                  ];
                } else {
                  match$1 = [
                    [],
                    []
                  ];
                }
              } else {
                match$1 = [
                  [],
                  []
                ];
              }
            } else {
              match$1 = [
                [],
                []
              ];
            }
          } else {
            match$1 = [
              [],
              []
            ];
          }
        } else {
          match$1 = [
            [],
            []
          ];
        }
      } else {
        match$1 = [
          [],
          []
        ];
      }
    } else {
      match$1 = [
        match.dates,
        match.texts
      ];
    }
    newState = {
      TAG: "Loaded",
      lastAnswer: questionState.lastAnswer,
      lastNewAnswer: lastNewAnswer,
      question: questionState.question,
      level: questionState.level,
      dates: match$1[0],
      texts: match$1[1]
    };
  }
  return Mithril.setState(vnode, newState);
}

function make() {
  return Mithril.view(Mithril.oncreate(Mithril.component("NotReady"), Forms.Input.focus), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var texts = match.texts;
                  var dates = match.dates;
                  var question = match.question;
                  var match$1 = question.kind;
                  if (typeof match$1 === "object" && match$1.NAME === "MultipleDatesTextsQuestion") {
                    var options = match$1.VAL;
                    tmp = ReactDOM.jsxs("div", {
                          children: [
                            ReactDOM.jsx("div", {
                                  children: question.name,
                                  className: "tile-content text-center text-small"
                                }),
                            ReactDOM.jsx("br", {}),
                            options.dates_labels.map(function (label, index) {
                                  var match = Prelude.default(Curry._2(Prelude.$$Array.get, dates, index), [
                                        "",
                                        undefined
                                      ]);
                                  return ReactDOM.jsxs("div", {
                                              children: [
                                                ReactDOM.jsx("label", {
                                                      children: label,
                                                      className: "form-label",
                                                      htmlFor: question.id + String(index) + "-date"
                                                    }),
                                                ReactDOM.jsx("input", {
                                                      className: "form-input",
                                                      id: question.id + String(index) + "-date",
                                                      type: "date",
                                                      value: match[0],
                                                      onChange: (function (extra) {
                                                          var match = vnode.state.data;
                                                          if (typeof match !== "object") {
                                                            return ;
                                                          }
                                                          var question = match.question;
                                                          var match$1 = question.kind;
                                                          return Mithril.setState(vnode, {
                                                                      TAG: "Loaded",
                                                                      lastAnswer: match.lastAnswer,
                                                                      lastNewAnswer: match.lastNewAnswer,
                                                                      question: question,
                                                                      level: match.level,
                                                                      dates: typeof match$1 === "object" ? (
                                                                          match$1.NAME === "MultipleDatesTextsQuestion" ? match$1.VAL.dates_labels.map(function (param, index) {
                                                                                  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[id='" + question.id + String(index) + "-date']")), (function (v) {
                                                                                                    return [
                                                                                                            v,
                                                                                                            DateQuestionUtils.dateFromString(v)
                                                                                                          ];
                                                                                                  })), [
                                                                                              "",
                                                                                              undefined
                                                                                            ]);
                                                                                }) : []
                                                                        ) : [],
                                                                      texts: match.texts
                                                                    });
                                                        })
                                                    })
                                              ],
                                              className: "m-2"
                                            });
                                }),
                            options.texts_labels.map(function (label, index) {
                                  var value = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, texts[index], (function (v) {
                                              return v;
                                            })), "");
                                  return ReactDOM.jsx("div", {
                                              children: ReactDOM.jsxs("div", {
                                                    children: [
                                                      ReactDOM.jsx("label", {
                                                            children: label,
                                                            className: "form-label col-9"
                                                          }),
                                                      ReactDOM.jsx("input", {
                                                            className: "form-input",
                                                            id: question.id + String(index) + "-text",
                                                            style: {
                                                              height: "60%",
                                                              width: "100%"
                                                            },
                                                            name: question.id,
                                                            type: "text",
                                                            value: value,
                                                            onKeyUp: (function (extra) {
                                                                var match = vnode.state.data;
                                                                if (typeof match !== "object") {
                                                                  return ;
                                                                }
                                                                var question = match.question;
                                                                var match$1 = question.kind;
                                                                return Mithril.setState(vnode, {
                                                                            TAG: "Loaded",
                                                                            lastAnswer: match.lastAnswer,
                                                                            lastNewAnswer: match.lastNewAnswer,
                                                                            question: question,
                                                                            level: match.level,
                                                                            dates: match.dates,
                                                                            texts: typeof match$1 === "object" ? (
                                                                                match$1.NAME === "MultipleDatesTextsQuestion" ? match$1.VAL.texts_labels.map(function (param, index) {
                                                                                        var val = Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[id='" + question.id + String(index) + "-text']"));
                                                                                        if (Caml_obj.notequal(val, "")) {
                                                                                          return val;
                                                                                        }
                                                                                        
                                                                                      }) : []
                                                                              ) : []
                                                                          });
                                                              })
                                                          })
                                                    ],
                                                    className: "form-group"
                                                  }),
                                              className: "columns m-2"
                                            });
                                })
                          ],
                          className: "form-group"
                        });
                  } else {
                    tmp = Mithril.empty;
                  }
                }
                return React.jsx(PreVettingQuestionWidget.Editor.make, {
                            getValue: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return {
                                          attachments: []
                                        };
                                }
                                var dates = match.dates.map(function (param) {
                                      return param[1];
                                    });
                                return {
                                        value: {
                                          NAME: "Value",
                                          VAL: {
                                            NAME: "MultipleDatesTexts",
                                            VAL: [
                                              dates,
                                              match.texts
                                            ]
                                          }
                                        },
                                        attachments: []
                                      };
                              }),
                            allowSave: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var match$1 = match.question.kind;
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "MultipleDatesTextsQuestion") {
                                  return false;
                                }
                                var options = match$1.VAL;
                                var dates = match.dates.map(function (param) {
                                      return param[1];
                                    });
                                if (options.dates_labels.length === Prelude.$$Array.keepSome(dates).length) {
                                  return options.texts_labels.length === Prelude.$$Array.keepSome(match.texts).length;
                                } else {
                                  return false;
                                }
                              }),
                            isDirty: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var texts = match.texts;
                                var lastNewAnswer = match.lastNewAnswer;
                                var dates = match.dates.map(function (param) {
                                      return param[1];
                                    });
                                var any = function (values) {
                                  return Curry._2(Prelude.$$Array.some, values, Prelude.OptionExported.$$Option.isSome);
                                };
                                var hasAnyValue = function () {
                                  if (any(dates)) {
                                    return true;
                                  } else {
                                    return any(texts);
                                  }
                                };
                                if (createNew || Curry._1(Prelude.OptionExported.$$Option.isNone, lastNewAnswer)) {
                                  return hasAnyValue();
                                }
                                if (lastNewAnswer === undefined) {
                                  return false;
                                }
                                var match$1 = lastNewAnswer.value;
                                if (match$1 === undefined) {
                                  return false;
                                }
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "Value") {
                                  return false;
                                }
                                var match$2 = match$1.VAL;
                                if (typeof match$2 !== "object") {
                                  return false;
                                }
                                if (match$2.NAME !== "MultipleDatesTexts") {
                                  return false;
                                }
                                var match$3 = match$2.VAL;
                                if (Caml_obj.notequal(match$3[0], dates)) {
                                  return true;
                                } else {
                                  return Caml_obj.notequal(match$3[1], texts);
                                }
                              }),
                            updateChildrenState: (function (extra) {
                                return updateState(vnode, extra);
                              }),
                            children: tmp
                          });
              }));
}

function make$1(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var question = vnode.attrs.question;
                var lastAnswer = vnode.attrs.lastAnswer;
                var lastNewAnswer = vnode.attrs.lastNewAnswer;
                var level = vnode.attrs.level;
                var vessel = vnode.attrs.vessel;
                var preVettingModule = vnode.attrs.preVettingModule;
                var isAlreadyAnswered = Belt_Option.isSome(lastNewAnswer);
                return React.jsx(PreVettingQuestionWidget.make, {
                            question: question,
                            level: level,
                            vessel: vessel,
                            lastNewAnswer: lastNewAnswer,
                            lastAnswer: lastAnswer,
                            children: ReactDOM.jsx("button", {
                                  children: React.jsx(Feather.make, {
                                        icon: "calendar"
                                      }),
                                  className: Mithril.$$class([
                                        [
                                          "btn btn-action",
                                          true
                                        ],
                                        [
                                          "active",
                                          isAlreadyAnswered
                                        ],
                                        [
                                          "bg-gray",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered
                                        ]
                                      ], undefined),
                                  style: {
                                    border: "none"
                                  },
                                  onClick: (function (extra) {
                                      return PreVettingQuestionWidget.startInspection(vnode, extra);
                                    })
                                }),
                            preVettingModule: preVettingModule
                          });
              }));
}

var Editor = {
  make: make
};

export {
  Editor ,
  make$1 as make,
}
/* Forms Not a pure module */
