// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as ButtonFilterModal from "../../common/components/ButtonFilterModal.js";
import * as FilterModalContainer from "../../common/components/FilterModalContainer.js";

function activeFilters(filter) {
  var filtersOn = [];
  if (filter.checklist) {
    filtersOn.push("checklist");
  }
  if (filter.structural) {
    filtersOn.push("structural");
  }
  if (filter.safety) {
    filtersOn.push("safety");
  }
  if (filter.sire) {
    filtersOn.push("sire");
  }
  if (filter.r4schecklist) {
    filtersOn.push("r4schecklist");
  }
  if (filter.yes) {
    filtersOn.push("yes");
  }
  if (filter.no) {
    filtersOn.push("no");
  }
  return filtersOn;
}

function activeAreaFilter(filter) {
  var match = filter.checklist;
  var match$1 = filter.structural;
  var match$2 = filter.safety;
  var match$3 = filter.sire;
  var match$4 = filter.r4schecklist;
  if (match) {
    if (match$1 && match$2 && match$3 && match$4) {
      return false;
    } else {
      return true;
    }
  } else if (match$1 || match$2 || match$3 || match$4) {
    return true;
  } else {
    return false;
  }
}

function activeDeadlineFilter(filter) {
  return filter.yes !== filter.no;
}

function anyFilter(filter) {
  if (filter.checklist || filter.structural || filter.safety || filter.sire || filter.r4schecklist || filter.yes) {
    return true;
  } else {
    return filter.no;
  }
}

function isOn(filter, state) {
  if (filter === "no") {
    return state.no;
  } else if (filter === "yes") {
    return state.yes;
  } else if (filter === "r4schecklist") {
    return state.r4schecklist;
  } else if (filter === "safety") {
    return state.safety;
  } else if (filter === "structural") {
    return state.structural;
  } else if (filter === "checklist") {
    return state.checklist;
  } else {
    return state.sire;
  }
}

function toLabel(filter) {
  if (filter === "no") {
    return "No Deadline";
  } else if (filter === "yes") {
    return "Deadline set";
  } else if (filter === "r4schecklist") {
    return "Port checklist";
  } else if (filter === "safety") {
    return "Safety";
  } else if (filter === "structural") {
    return "Hull & Equipment";
  } else if (filter === "checklist") {
    return "Checklist";
  } else {
    return "SIRE 2.0";
  }
}

function toLabelInfo(filter, values) {
  if (filter === "no") {
    return "No (" + String(values.no) + ")";
  } else if (filter === "yes") {
    return "Yes (" + String(values.yes) + ")";
  } else if (filter === "r4schecklist") {
    return "Port checklist (" + String(values.r4schecklist) + ")";
  } else if (filter === "safety") {
    return "Safety (" + String(values.safety) + ")";
  } else if (filter === "structural") {
    return "Hull & Equipment (" + String(values.structural) + ")";
  } else if (filter === "checklist") {
    return "Checklist (" + String(values.checklist) + ")";
  } else {
    return "SIRE 2.0 (" + String(values.sire) + ")";
  }
}

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var showModal = vnode.attrs.showModal;
                var filters = vnode.attrs.filters;
                var values = vnode.attrs.values;
                var disabledFilters = vnode.attrs.disabledFilters;
                var filterUpdate = vnode.attrs.filterUpdate;
                var onClear = vnode.attrs.onClear;
                var onClose = vnode.attrs.onClose;
                var toButtonFilter = function (filter) {
                  var onTheme = ButtonFilterModal.Theme.on;
                  var offTheme = ButtonFilterModal.Theme.off;
                  return React.jsx(ButtonFilterModal.make, {
                              text: toLabelInfo(filter, values),
                              onClick: (function (_event) {
                                  filterUpdate(filter, !isOn(filter, filters));
                                }),
                              disabled: isOn(filter, disabledFilters),
                              theme: isOn(filter, filters) ? onTheme : offTheme
                            });
                };
                return React.jsx(FilterModalContainer.make, {
                            children: ReactDOM.jsxs("div", {
                                  children: [
                                    ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("div", {
                                                  children: ReactDOM.jsx("label", {
                                                        children: "Area:",
                                                        className: "tw-font-medium"
                                                      }),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: toButtonFilter("checklist"),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: toButtonFilter("structural"),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: toButtonFilter("safety"),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: toButtonFilter("sire"),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsx("div", {
                                                  children: toButtonFilter("r4schecklist"),
                                                  className: "tw-flex tw-flex-row"
                                                })
                                          ],
                                          className: "tw-flex tw-flex-col tw-justify-start tw-gap-2"
                                        }),
                                    ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsx("div", {
                                                  children: ReactDOM.jsx("label", {
                                                        children: "Deadline set:",
                                                        className: "tw-font-medium"
                                                      }),
                                                  className: "tw-flex tw-flex-row"
                                                }),
                                            ReactDOM.jsxs("div", {
                                                  children: [
                                                    toButtonFilter("yes"),
                                                    toButtonFilter("no")
                                                  ],
                                                  className: "tw-flex tw-flex-row tw-justify-start tw-gap-2"
                                                })
                                          ],
                                          className: "tw-flex tw-flex-col tw-justify-start tw-gap-2"
                                        })
                                  ],
                                  className: "tw-flex tw-flex-col tw-justify-start tw-gap-4"
                                }),
                            showModal: showModal,
                            textApplyButton: "Apply Filter (" + String(values.currentTotal) + ")",
                            onClear: onClear,
                            onClose: onClose,
                            onApplyFilter: onClose
                          });
              }));
}

var Filter_defaultState = {
  checklist: false,
  structural: false,
  safety: false,
  sire: false,
  r4schecklist: false,
  yes: false,
  no: false,
  risq: false
};

var Filter = {
  defaultState: Filter_defaultState,
  activeAreaFilter: activeAreaFilter,
  anyFilter: anyFilter,
  activeDeadlineFilter: activeDeadlineFilter,
  activeFilters: activeFilters,
  toLabel: toLabel
};

export {
  Filter ,
  make ,
}
/* React Not a pure module */
