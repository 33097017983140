// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function getCompleteInspection(request) {
  if (request.TAG === "Inspection") {
    return request._1;
  }
  
}

function isCompleteInspectionRequest(request) {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, getCompleteInspection(request));
}

function getIncompleteInspection(request) {
  if (request.TAG === "IncompleteInspection") {
    return request._1;
  }
  
}

function isIncompleteInspectionRequest(request) {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, getIncompleteInspection(request));
}

function getAnswer(request) {
  if (request.TAG === "Answer") {
    return request._1;
  }
  
}

function isAnswerRequest(request) {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, getAnswer(request));
}

function getRisqAnswer(request) {
  if (request.TAG === "RisqAnswer") {
    return request._1;
  }
  
}

function isPreVettingAnswerRequest(request) {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, getRisqAnswer(request));
}

function getNewComment(request) {
  if (request.TAG === "Comment") {
    return request._1;
  }
  
}

function isNewCommentRequest(request) {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, getNewComment(request));
}

function getUpdatedComment(request) {
  if (request.TAG === "UpdatedComment") {
    return request._1;
  }
  
}

function isUpdatedCommentRequest(request) {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, getUpdatedComment(request));
}

function getUserProfile(request) {
  if (request.TAG === "UserProfile") {
    return request._1;
  }
  
}

function isUserProfileRequest(request) {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, getUserProfile(request));
}

function getCompleteCompanyFormSubmission(request) {
  if (request.TAG === "CompanyFormSubmission") {
    return [
            request._1,
            request._2
          ];
  }
  
}

function isCompleteCompanyFormSubmissionRequest(request) {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, getCompleteCompanyFormSubmission(request));
}

function getIncompleteCompanyForm(request) {
  if (request.TAG === "IncompleteCompanyForm") {
    return request._1;
  }
  
}

function isIncompleteFormRequest(request) {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, getIncompleteCompanyForm(request));
}

function getLevelSubmission(request) {
  if (request.TAG === "LevelSubmission") {
    return request._1;
  }
  
}

function isLevelSubmissionRequest(request) {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, getLevelSubmission(request));
}

function getProcedureUpdate(request) {
  if (request.TAG === "ProcedureUpdate") {
    return request._1;
  }
  
}

function isProcedureUpdateRequest(request) {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, getProcedureUpdate(request));
}

function getReadNotificationEntry(request) {
  if (request.TAG === "ReadNotificationEntry") {
    return [
            request._1,
            request._2
          ];
  }
  
}

function isReadNotificationEntryRequest(request) {
  return Curry._1(Prelude.OptionExported.$$Option.isSome, getReadNotificationEntry(request));
}

function fromInspection(vessel, inspection) {
  return {
          TAG: "Inspection",
          _0: vessel,
          _1: inspection
        };
}

function fromIncompleteInspection(vessel, inspection) {
  return {
          TAG: "IncompleteInspection",
          _0: vessel,
          _1: inspection
        };
}

function fromProcedureUpdate(vessel, procedure) {
  return {
          TAG: "ProcedureUpdate",
          _0: vessel,
          _1: procedure
        };
}

function fromReadNotificationEntry(vessel, entry, notification) {
  return {
          TAG: "ReadNotificationEntry",
          _0: vessel,
          _1: entry,
          _2: notification
        };
}

function fromComment(vessel, comment) {
  return {
          TAG: "Comment",
          _0: vessel,
          _1: comment
        };
}

function fromUpdatedComment(vessel, comment) {
  return {
          TAG: "UpdatedComment",
          _0: vessel,
          _1: comment
        };
}

function fromAnswer(vessel, answer) {
  return {
          TAG: "Answer",
          _0: vessel,
          _1: answer
        };
}

function fromRisqAnswer(vessel, answer) {
  return {
          TAG: "RisqAnswer",
          _0: vessel,
          _1: answer
        };
}

function fromUserProfile(vessel, profile) {
  return {
          TAG: "UserProfile",
          _0: vessel,
          _1: profile
        };
}

function fromCompanyFormSubmission(vessel, submission, form) {
  return {
          TAG: "CompanyFormSubmission",
          _0: vessel,
          _1: submission,
          _2: form
        };
}

function fromIncompleteCompanyForm(vessel, form) {
  return {
          TAG: "IncompleteCompanyForm",
          _0: vessel,
          _1: form
        };
}

function fromLevelSubmission(vessel, submission) {
  return {
          TAG: "LevelSubmission",
          _0: vessel,
          _1: submission
        };
}

function fromAttachmentChunkGroup(vessel, attachment) {
  return {
          TAG: "FutureAttachment",
          _0: vessel,
          _1: attachment
        };
}

function walked(request) {
  switch (request.TAG) {
    case "Inspection" :
    case "Answer" :
    case "RisqAnswer" :
        return request._1.walked_steps;
    default:
      return ;
  }
}

function timestamp(request) {
  switch (request.TAG) {
    case "Inspection" :
        return Caml_option.some(request._1.timestamp);
    case "Answer" :
    case "RisqAnswer" :
        return Caml_option.some(request._1.timestamp);
    case "Comment" :
        return Caml_option.some(request._1.timestamp);
    case "CompanyFormSubmission" :
    case "LevelSubmission" :
        return Caml_option.some(request._1.timestamp);
    default:
      return ;
  }
}

function getVessel(request) {
  return request._0;
}

function getCompleteInspection$1(request) {
  if (request.TAG === "Inspection") {
    return request._1;
  }
  
}

function getIncompleteInspection$1(request) {
  if (request.TAG === "IncompleteInspection") {
    return request._1;
  }
  
}

function getInspection(request) {
  switch (request.TAG) {
    case "Inspection" :
    case "IncompleteInspection" :
        return request._1;
    default:
      return ;
  }
}

function getFutureAttachment(request) {
  if (request.TAG === "FutureAttachment") {
    return request._1;
  }
  
}

function cmp(source1, source2) {
  switch (source1.TAG) {
    case "Inspection" :
        switch (source2.TAG) {
          case "Inspection" :
          case "IncompleteInspection" :
              break;
          default:
            return 1;
        }
        return Caml_obj.compare(source1._1.id, source2._1.id);
    case "IncompleteInspection" :
        switch (source2.TAG) {
          case "Inspection" :
          case "IncompleteInspection" :
              break;
          default:
            return 1;
        }
        return Caml_obj.compare(source1._1.id, source2._1.id);
    case "Answer" :
        if (source2.TAG === "Answer") {
          return Caml_obj.compare(source1._1.id, source2._1.id);
        } else {
          return 1;
        }
    case "RisqAnswer" :
        if (source2.TAG === "RisqAnswer") {
          return Caml_obj.compare(source1._1.id, source2._1.id);
        } else {
          return 1;
        }
    case "Comment" :
        if (source2.TAG === "Comment") {
          return Caml_obj.compare(source1._1.id, source2._1.id);
        } else {
          return 1;
        }
    default:
      return 1;
  }
}

var Comparator = Belt_Id.MakeComparableU({
      cmp: cmp
    });

export {
  isCompleteInspectionRequest ,
  isIncompleteInspectionRequest ,
  getAnswer ,
  isAnswerRequest ,
  getRisqAnswer ,
  isPreVettingAnswerRequest ,
  getNewComment ,
  isNewCommentRequest ,
  getUpdatedComment ,
  isUpdatedCommentRequest ,
  getUserProfile ,
  isUserProfileRequest ,
  getCompleteCompanyFormSubmission ,
  isCompleteCompanyFormSubmissionRequest ,
  getIncompleteCompanyForm ,
  isIncompleteFormRequest ,
  getLevelSubmission ,
  isLevelSubmissionRequest ,
  getProcedureUpdate ,
  isProcedureUpdateRequest ,
  getReadNotificationEntry ,
  isReadNotificationEntryRequest ,
  fromInspection ,
  fromIncompleteInspection ,
  fromProcedureUpdate ,
  fromReadNotificationEntry ,
  fromComment ,
  fromUpdatedComment ,
  fromAnswer ,
  fromRisqAnswer ,
  fromUserProfile ,
  fromCompanyFormSubmission ,
  fromIncompleteCompanyForm ,
  fromLevelSubmission ,
  fromAttachmentChunkGroup ,
  walked ,
  timestamp ,
  getVessel ,
  getCompleteInspection$1 as getCompleteInspection,
  getIncompleteInspection$1 as getIncompleteInspection,
  getInspection ,
  getFutureAttachment ,
  Comparator ,
}
/* Comparator Not a pure module */
