// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Query from "../../Query.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as RisqQuery from "../../RisqQuery.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.js";

function MakeIndexCounter(T) {
  var storeName = T.storeName;
  var index = T.index;
  var range = T.range;
  var count = function (transaction) {
    return new Promise((function (resolve, reject) {
                  var x = range[0];
                  var keyRange;
                  if (typeof x !== "object") {
                    var y = range[1];
                    keyRange = typeof y !== "object" ? undefined : (
                        y.TAG === "Included" ? Caml_option.some(IDBKeyRange.upperBound(y._0, false)) : Caml_option.some(IDBKeyRange.upperBound(y._0, true))
                      );
                  } else if (x.TAG === "Included") {
                    var y$1 = range[1];
                    var x$1 = x._0;
                    if (typeof y$1 !== "object") {
                      keyRange = Caml_option.some(IDBKeyRange.lowerBound(x$1, false));
                    } else if (y$1.TAG === "Included") {
                      var y$2 = y$1._0;
                      keyRange = Caml_obj.equal(x$1, y$2) ? Caml_option.some(IDBKeyRange.only(x$1)) : Caml_option.some(IDBKeyRange.bound(x$1, y$2, false, false));
                    } else {
                      keyRange = Caml_option.some(IDBKeyRange.bound(x$1, y$1._0, false, true));
                    }
                  } else {
                    var y$3 = range[1];
                    var x$2 = x._0;
                    keyRange = typeof y$3 !== "object" ? Caml_option.some(IDBKeyRange.lowerBound(x$2, true)) : (
                        y$3.TAG === "Included" ? Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, false)) : Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, true))
                      );
                  }
                  var idx = transaction.objectStore(storeName).index(index);
                  var request = keyRange !== undefined ? idx.count(Caml_option.valFromOption(keyRange)) : idx.count();
                  request.onsuccess = (function (param) {
                      resolve(Core__Option.getOr(request.result, 0));
                    });
                }));
  };
  return {
          storeName: storeName,
          index: index,
          range: range,
          count: count
        };
}

function MakeStoreCounter(T) {
  var storeName = T.storeName;
  var count = function (transaction) {
    return new Promise((function (resolve, reject) {
                  var request = transaction.objectStore(storeName).count();
                  request.onsuccess = (function (param) {
                      resolve(Core__Option.getOr(request.result, 0));
                    });
                }));
  };
  return {
          storeName: storeName,
          count: count
        };
}

var $$default = {
  profiles: 0,
  newInspections: 0,
  newAnswers: 0,
  procedureUpdates: 0,
  newComments: 0,
  updatedComments: 0,
  notificationEntries: 0,
  futureAttachments: 0,
  newCompanyFormSubmissions: 0,
  readNotificationEntries: 0,
  risqNewAnswers: 0
};

async function count(vesselId) {
  var storeName = "newUserProfiles";
  var range_0 = {
    TAG: "Included",
    _0: vesselId
  };
  var range_1 = {
    TAG: "Included",
    _0: vesselId
  };
  var count$1 = function (transaction) {
    return new Promise((function (resolve, reject) {
                  var x = range_0;
                  var keyRange;
                  if (typeof x !== "object") {
                    var y = range_1;
                    keyRange = typeof y !== "object" ? undefined : (
                        y.TAG === "Included" ? Caml_option.some(IDBKeyRange.upperBound(y._0, false)) : Caml_option.some(IDBKeyRange.upperBound(y._0, true))
                      );
                  } else if (x.TAG === "Included") {
                    var y$1 = range_1;
                    var x$1 = x._0;
                    if (typeof y$1 !== "object") {
                      keyRange = Caml_option.some(IDBKeyRange.lowerBound(x$1, false));
                    } else if (y$1.TAG === "Included") {
                      var y$2 = y$1._0;
                      keyRange = Caml_obj.equal(x$1, y$2) ? Caml_option.some(IDBKeyRange.only(x$1)) : Caml_option.some(IDBKeyRange.bound(x$1, y$2, false, false));
                    } else {
                      keyRange = Caml_option.some(IDBKeyRange.bound(x$1, y$1._0, false, true));
                    }
                  } else {
                    var y$3 = range_1;
                    var x$2 = x._0;
                    keyRange = typeof y$3 !== "object" ? Caml_option.some(IDBKeyRange.lowerBound(x$2, true)) : (
                        y$3.TAG === "Included" ? Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, false)) : Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, true))
                      );
                  }
                  var idx = transaction.objectStore(storeName).index("vessel");
                  var request = keyRange !== undefined ? idx.count(Caml_option.valFromOption(keyRange)) : idx.count();
                  request.onsuccess = (function (param) {
                      resolve(Core__Option.getOr(request.result, 0));
                    });
                }));
  };
  var storeName$1 = "newInspections";
  var range_0$1 = {
    TAG: "Included",
    _0: vesselId
  };
  var range_1$1 = {
    TAG: "Included",
    _0: vesselId
  };
  var count$2 = function (transaction) {
    return new Promise((function (resolve, reject) {
                  var x = range_0$1;
                  var keyRange;
                  if (typeof x !== "object") {
                    var y = range_1$1;
                    keyRange = typeof y !== "object" ? undefined : (
                        y.TAG === "Included" ? Caml_option.some(IDBKeyRange.upperBound(y._0, false)) : Caml_option.some(IDBKeyRange.upperBound(y._0, true))
                      );
                  } else if (x.TAG === "Included") {
                    var y$1 = range_1$1;
                    var x$1 = x._0;
                    if (typeof y$1 !== "object") {
                      keyRange = Caml_option.some(IDBKeyRange.lowerBound(x$1, false));
                    } else if (y$1.TAG === "Included") {
                      var y$2 = y$1._0;
                      keyRange = Caml_obj.equal(x$1, y$2) ? Caml_option.some(IDBKeyRange.only(x$1)) : Caml_option.some(IDBKeyRange.bound(x$1, y$2, false, false));
                    } else {
                      keyRange = Caml_option.some(IDBKeyRange.bound(x$1, y$1._0, false, true));
                    }
                  } else {
                    var y$3 = range_1$1;
                    var x$2 = x._0;
                    keyRange = typeof y$3 !== "object" ? Caml_option.some(IDBKeyRange.lowerBound(x$2, true)) : (
                        y$3.TAG === "Included" ? Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, false)) : Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, true))
                      );
                  }
                  var idx = transaction.objectStore(storeName$1).index("vessel_id");
                  var request = keyRange !== undefined ? idx.count(Caml_option.valFromOption(keyRange)) : idx.count();
                  request.onsuccess = (function (param) {
                      resolve(Core__Option.getOr(request.result, 0));
                    });
                }));
  };
  var storeName$2 = "newAnswers";
  var range_0$2 = {
    TAG: "Included",
    _0: vesselId
  };
  var range_1$2 = {
    TAG: "Included",
    _0: vesselId
  };
  var count$3 = function (transaction) {
    return new Promise((function (resolve, reject) {
                  var x = range_0$2;
                  var keyRange;
                  if (typeof x !== "object") {
                    var y = range_1$2;
                    keyRange = typeof y !== "object" ? undefined : (
                        y.TAG === "Included" ? Caml_option.some(IDBKeyRange.upperBound(y._0, false)) : Caml_option.some(IDBKeyRange.upperBound(y._0, true))
                      );
                  } else if (x.TAG === "Included") {
                    var y$1 = range_1$2;
                    var x$1 = x._0;
                    if (typeof y$1 !== "object") {
                      keyRange = Caml_option.some(IDBKeyRange.lowerBound(x$1, false));
                    } else if (y$1.TAG === "Included") {
                      var y$2 = y$1._0;
                      keyRange = Caml_obj.equal(x$1, y$2) ? Caml_option.some(IDBKeyRange.only(x$1)) : Caml_option.some(IDBKeyRange.bound(x$1, y$2, false, false));
                    } else {
                      keyRange = Caml_option.some(IDBKeyRange.bound(x$1, y$1._0, false, true));
                    }
                  } else {
                    var y$3 = range_1$2;
                    var x$2 = x._0;
                    keyRange = typeof y$3 !== "object" ? Caml_option.some(IDBKeyRange.lowerBound(x$2, true)) : (
                        y$3.TAG === "Included" ? Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, false)) : Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, true))
                      );
                  }
                  var idx = transaction.objectStore(storeName$2).index("vessel_id");
                  var request = keyRange !== undefined ? idx.count(Caml_option.valFromOption(keyRange)) : idx.count();
                  request.onsuccess = (function (param) {
                      resolve(Core__Option.getOr(request.result, 0));
                    });
                }));
  };
  var storeName$3 = "newAnswers";
  var range_0$3 = {
    TAG: "Included",
    _0: vesselId
  };
  var range_1$3 = {
    TAG: "Included",
    _0: vesselId
  };
  var count$4 = function (transaction) {
    return new Promise((function (resolve, reject) {
                  var x = range_0$3;
                  var keyRange;
                  if (typeof x !== "object") {
                    var y = range_1$3;
                    keyRange = typeof y !== "object" ? undefined : (
                        y.TAG === "Included" ? Caml_option.some(IDBKeyRange.upperBound(y._0, false)) : Caml_option.some(IDBKeyRange.upperBound(y._0, true))
                      );
                  } else if (x.TAG === "Included") {
                    var y$1 = range_1$3;
                    var x$1 = x._0;
                    if (typeof y$1 !== "object") {
                      keyRange = Caml_option.some(IDBKeyRange.lowerBound(x$1, false));
                    } else if (y$1.TAG === "Included") {
                      var y$2 = y$1._0;
                      keyRange = Caml_obj.equal(x$1, y$2) ? Caml_option.some(IDBKeyRange.only(x$1)) : Caml_option.some(IDBKeyRange.bound(x$1, y$2, false, false));
                    } else {
                      keyRange = Caml_option.some(IDBKeyRange.bound(x$1, y$1._0, false, true));
                    }
                  } else {
                    var y$3 = range_1$3;
                    var x$2 = x._0;
                    keyRange = typeof y$3 !== "object" ? Caml_option.some(IDBKeyRange.lowerBound(x$2, true)) : (
                        y$3.TAG === "Included" ? Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, false)) : Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, true))
                      );
                  }
                  var idx = transaction.objectStore(storeName$3).index("vessel_id");
                  var request = keyRange !== undefined ? idx.count(Caml_option.valFromOption(keyRange)) : idx.count();
                  request.onsuccess = (function (param) {
                      resolve(Core__Option.getOr(request.result, 0));
                    });
                }));
  };
  var storeName$4 = "procedureUpdates";
  var range_0$4 = {
    TAG: "Included",
    _0: vesselId
  };
  var range_1$4 = {
    TAG: "Included",
    _0: vesselId
  };
  var count$5 = function (transaction) {
    return new Promise((function (resolve, reject) {
                  var x = range_0$4;
                  var keyRange;
                  if (typeof x !== "object") {
                    var y = range_1$4;
                    keyRange = typeof y !== "object" ? undefined : (
                        y.TAG === "Included" ? Caml_option.some(IDBKeyRange.upperBound(y._0, false)) : Caml_option.some(IDBKeyRange.upperBound(y._0, true))
                      );
                  } else if (x.TAG === "Included") {
                    var y$1 = range_1$4;
                    var x$1 = x._0;
                    if (typeof y$1 !== "object") {
                      keyRange = Caml_option.some(IDBKeyRange.lowerBound(x$1, false));
                    } else if (y$1.TAG === "Included") {
                      var y$2 = y$1._0;
                      keyRange = Caml_obj.equal(x$1, y$2) ? Caml_option.some(IDBKeyRange.only(x$1)) : Caml_option.some(IDBKeyRange.bound(x$1, y$2, false, false));
                    } else {
                      keyRange = Caml_option.some(IDBKeyRange.bound(x$1, y$1._0, false, true));
                    }
                  } else {
                    var y$3 = range_1$4;
                    var x$2 = x._0;
                    keyRange = typeof y$3 !== "object" ? Caml_option.some(IDBKeyRange.lowerBound(x$2, true)) : (
                        y$3.TAG === "Included" ? Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, false)) : Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, true))
                      );
                  }
                  var idx = transaction.objectStore(storeName$4).index("vessel_id");
                  var request = keyRange !== undefined ? idx.count(Caml_option.valFromOption(keyRange)) : idx.count();
                  request.onsuccess = (function (param) {
                      resolve(Core__Option.getOr(request.result, 0));
                    });
                }));
  };
  var storeName$5 = "newComments";
  var range_0$5 = {
    TAG: "Included",
    _0: vesselId
  };
  var range_1$5 = {
    TAG: "Included",
    _0: vesselId
  };
  var count$6 = function (transaction) {
    return new Promise((function (resolve, reject) {
                  var x = range_0$5;
                  var keyRange;
                  if (typeof x !== "object") {
                    var y = range_1$5;
                    keyRange = typeof y !== "object" ? undefined : (
                        y.TAG === "Included" ? Caml_option.some(IDBKeyRange.upperBound(y._0, false)) : Caml_option.some(IDBKeyRange.upperBound(y._0, true))
                      );
                  } else if (x.TAG === "Included") {
                    var y$1 = range_1$5;
                    var x$1 = x._0;
                    if (typeof y$1 !== "object") {
                      keyRange = Caml_option.some(IDBKeyRange.lowerBound(x$1, false));
                    } else if (y$1.TAG === "Included") {
                      var y$2 = y$1._0;
                      keyRange = Caml_obj.equal(x$1, y$2) ? Caml_option.some(IDBKeyRange.only(x$1)) : Caml_option.some(IDBKeyRange.bound(x$1, y$2, false, false));
                    } else {
                      keyRange = Caml_option.some(IDBKeyRange.bound(x$1, y$1._0, false, true));
                    }
                  } else {
                    var y$3 = range_1$5;
                    var x$2 = x._0;
                    keyRange = typeof y$3 !== "object" ? Caml_option.some(IDBKeyRange.lowerBound(x$2, true)) : (
                        y$3.TAG === "Included" ? Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, false)) : Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, true))
                      );
                  }
                  var idx = transaction.objectStore(storeName$5).index("vessel_id");
                  var request = keyRange !== undefined ? idx.count(Caml_option.valFromOption(keyRange)) : idx.count();
                  request.onsuccess = (function (param) {
                      resolve(Core__Option.getOr(request.result, 0));
                    });
                }));
  };
  var storeName$6 = "futureAttachments";
  var range_0$6 = {
    TAG: "Included",
    _0: vesselId
  };
  var range_1$6 = {
    TAG: "Included",
    _0: vesselId
  };
  var count$7 = function (transaction) {
    return new Promise((function (resolve, reject) {
                  var x = range_0$6;
                  var keyRange;
                  if (typeof x !== "object") {
                    var y = range_1$6;
                    keyRange = typeof y !== "object" ? undefined : (
                        y.TAG === "Included" ? Caml_option.some(IDBKeyRange.upperBound(y._0, false)) : Caml_option.some(IDBKeyRange.upperBound(y._0, true))
                      );
                  } else if (x.TAG === "Included") {
                    var y$1 = range_1$6;
                    var x$1 = x._0;
                    if (typeof y$1 !== "object") {
                      keyRange = Caml_option.some(IDBKeyRange.lowerBound(x$1, false));
                    } else if (y$1.TAG === "Included") {
                      var y$2 = y$1._0;
                      keyRange = Caml_obj.equal(x$1, y$2) ? Caml_option.some(IDBKeyRange.only(x$1)) : Caml_option.some(IDBKeyRange.bound(x$1, y$2, false, false));
                    } else {
                      keyRange = Caml_option.some(IDBKeyRange.bound(x$1, y$1._0, false, true));
                    }
                  } else {
                    var y$3 = range_1$6;
                    var x$2 = x._0;
                    keyRange = typeof y$3 !== "object" ? Caml_option.some(IDBKeyRange.lowerBound(x$2, true)) : (
                        y$3.TAG === "Included" ? Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, false)) : Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, true))
                      );
                  }
                  var idx = transaction.objectStore(storeName$6).index("vessel_id");
                  var request = keyRange !== undefined ? idx.count(Caml_option.valFromOption(keyRange)) : idx.count();
                  request.onsuccess = (function (param) {
                      resolve(Core__Option.getOr(request.result, 0));
                    });
                }));
  };
  var storeName$7 = "updatedComments";
  var count$8 = function (transaction) {
    return new Promise((function (resolve, reject) {
                  var request = transaction.objectStore(storeName$7).count();
                  request.onsuccess = (function (param) {
                      resolve(Core__Option.getOr(request.result, 0));
                    });
                }));
  };
  var storeName$8 = "newCompanyFormSubmissions";
  var range_0$7 = {
    TAG: "Included",
    _0: vesselId
  };
  var range_1$7 = {
    TAG: "Included",
    _0: vesselId
  };
  var count$9 = function (transaction) {
    return new Promise((function (resolve, reject) {
                  var x = range_0$7;
                  var keyRange;
                  if (typeof x !== "object") {
                    var y = range_1$7;
                    keyRange = typeof y !== "object" ? undefined : (
                        y.TAG === "Included" ? Caml_option.some(IDBKeyRange.upperBound(y._0, false)) : Caml_option.some(IDBKeyRange.upperBound(y._0, true))
                      );
                  } else if (x.TAG === "Included") {
                    var y$1 = range_1$7;
                    var x$1 = x._0;
                    if (typeof y$1 !== "object") {
                      keyRange = Caml_option.some(IDBKeyRange.lowerBound(x$1, false));
                    } else if (y$1.TAG === "Included") {
                      var y$2 = y$1._0;
                      keyRange = Caml_obj.equal(x$1, y$2) ? Caml_option.some(IDBKeyRange.only(x$1)) : Caml_option.some(IDBKeyRange.bound(x$1, y$2, false, false));
                    } else {
                      keyRange = Caml_option.some(IDBKeyRange.bound(x$1, y$1._0, false, true));
                    }
                  } else {
                    var y$3 = range_1$7;
                    var x$2 = x._0;
                    keyRange = typeof y$3 !== "object" ? Caml_option.some(IDBKeyRange.lowerBound(x$2, true)) : (
                        y$3.TAG === "Included" ? Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, false)) : Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, true))
                      );
                  }
                  var idx = transaction.objectStore(storeName$8).index("vessel_id");
                  var request = keyRange !== undefined ? idx.count(Caml_option.valFromOption(keyRange)) : idx.count();
                  request.onsuccess = (function (param) {
                      resolve(Core__Option.getOr(request.result, 0));
                    });
                }));
  };
  var storeName$9 = "readNotificationEntries";
  var range_0$8 = {
    TAG: "Included",
    _0: vesselId
  };
  var range_1$8 = {
    TAG: "Included",
    _0: vesselId
  };
  var count$10 = function (transaction) {
    return new Promise((function (resolve, reject) {
                  var x = range_0$8;
                  var keyRange;
                  if (typeof x !== "object") {
                    var y = range_1$8;
                    keyRange = typeof y !== "object" ? undefined : (
                        y.TAG === "Included" ? Caml_option.some(IDBKeyRange.upperBound(y._0, false)) : Caml_option.some(IDBKeyRange.upperBound(y._0, true))
                      );
                  } else if (x.TAG === "Included") {
                    var y$1 = range_1$8;
                    var x$1 = x._0;
                    if (typeof y$1 !== "object") {
                      keyRange = Caml_option.some(IDBKeyRange.lowerBound(x$1, false));
                    } else if (y$1.TAG === "Included") {
                      var y$2 = y$1._0;
                      keyRange = Caml_obj.equal(x$1, y$2) ? Caml_option.some(IDBKeyRange.only(x$1)) : Caml_option.some(IDBKeyRange.bound(x$1, y$2, false, false));
                    } else {
                      keyRange = Caml_option.some(IDBKeyRange.bound(x$1, y$1._0, false, true));
                    }
                  } else {
                    var y$3 = range_1$8;
                    var x$2 = x._0;
                    keyRange = typeof y$3 !== "object" ? Caml_option.some(IDBKeyRange.lowerBound(x$2, true)) : (
                        y$3.TAG === "Included" ? Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, false)) : Caml_option.some(IDBKeyRange.bound(x$2, y$3._0, true, true))
                      );
                  }
                  var idx = transaction.objectStore(storeName$9).index("vessel_id");
                  var request = keyRange !== undefined ? idx.count(Caml_option.valFromOption(keyRange)) : idx.count();
                  request.onsuccess = (function (param) {
                      resolve(Core__Option.getOr(request.result, 0));
                    });
                }));
  };
  var transaction = Query.Database.Patterns.transaction([
        storeName,
        storeName$1,
        storeName$2,
        storeName$4,
        storeName$5,
        storeName$6,
        storeName$7,
        storeName$8,
        storeName$9
      ], "readonly");
  var result;
  if (transaction.TAG === "Ok") {
    var transaction$1 = transaction._0;
    result = {
      profiles: await count$1(transaction$1),
      newInspections: await count$2(transaction$1),
      newAnswers: await count$3(transaction$1),
      procedureUpdates: await count$5(transaction$1),
      newComments: await count$6(transaction$1),
      updatedComments: await count$8(transaction$1),
      notificationEntries: 0,
      futureAttachments: await count$7(transaction$1),
      newCompanyFormSubmissions: await count$9(transaction$1),
      readNotificationEntries: await count$10(transaction$1),
      risqNewAnswers: 0
    };
  } else {
    console.error("UploadCounts", transaction._0);
    result = $$default;
  }
  var transaction$2 = RisqQuery.Database.Patterns.transaction([storeName$3], "readonly");
  if (transaction$2.TAG === "Ok") {
    return {
            profiles: result.profiles,
            newInspections: result.newInspections,
            newAnswers: result.newAnswers,
            procedureUpdates: result.procedureUpdates,
            newComments: result.newComments,
            updatedComments: result.updatedComments,
            notificationEntries: result.notificationEntries,
            futureAttachments: result.futureAttachments,
            newCompanyFormSubmissions: result.newCompanyFormSubmissions,
            readNotificationEntries: result.readNotificationEntries,
            risqNewAnswers: await count$4(transaction$2._0)
          };
  }
  console.error("UploadCounts", transaction$2._0);
  return result;
}

export {
  MakeIndexCounter ,
  MakeStoreCounter ,
  $$default as default,
  count ,
}
/* Query Not a pure module */
