// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as ApiMaker from "../../../libs/ApiMaker.js";
import * as LevelRiskStatus from "../../tree/LevelRiskStatus.js";

var url = "/tree/levels";

var fields = [
  [
    "id",
    "String"
  ],
  [
    "parent_id",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "vessel_id",
    "String"
  ],
  [
    "guidance_document_id",
    {
      TAG: "Optional",
      _0: "String"
    }
  ],
  [
    "name",
    "String"
  ],
  [
    "path",
    "String"
  ],
  [
    "number",
    {
      TAG: "Optional",
      _0: "Int"
    }
  ],
  [
    "depth",
    "Int"
  ],
  [
    "due_date",
    {
      TAG: "Optional",
      _0: "Date"
    }
  ],
  [
    "overdue_date",
    {
      TAG: "Optional",
      _0: "Date"
    }
  ],
  [
    "ignore_overdue_status",
    {
      TAG: "DefaultWhenInvalid",
      _0: "Boolean",
      _1: false
    }
  ],
  [
    "kind",
    "String"
  ],
  [
    "risk_status",
    {
      TAG: "Optional",
      _0: $$JSON.Field.variadicString("risk_status", LevelRiskStatus.tFromJs)
    }
  ]
];

var Definition = {
  url: url,
  fields: fields
};

var include = ApiMaker.MakeReadonlyAPIEndpoint({
      fields: fields,
      url: url
    });

var Deserializer = include.Deserializer;

var $$URL = include.$$URL;

var UnexpectedAPIPayload = include.UnexpectedAPIPayload;

var getMany = include.getMany;

var getAll = include.getAll;

var get = include.get;

var options = include.options;

export {
  Definition ,
  Deserializer ,
  $$URL ,
  UnexpectedAPIPayload ,
  getMany ,
  getAll ,
  get ,
  options ,
}
/* fields Not a pure module */
