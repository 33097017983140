// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Utils from "../../libs/Utils.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";

var Identifier = Utils.MakeOpaqueIdentifier({});

var _map = {"anywhere":"anywhere","aft_mooring_deck":"aft mooring deck","approaching_vessel":"approaching vessel","bow_loading_area":"bow loading area","bridge":"bridge","cargo_control_room":"cargo control room","cargo_manifold":"cargo manifold","chief_engineer_office":"chief engineer's office","compressor_room":"compressor room","documentation":"documentation","emergency_headquarters":"emergency headquarters","engine_control_room":"engine control room","engine_room":"engine room","exterior_decks":"exterior decks","forecastle":"forecastle","internal_accommodation":"internal accommodation","interview_deck_officer":"interview - deck officer","interview_deck_rating":"interview - deck rating","interview_electrician_eto":"interview - electrician / eto","interview_engine_rating":"interview - engine rating","interview_engineer_officer":"interview - engineer officer","interview_galley_rating":"interview - galley rating","interview_rating":"interview - rating","interview_security_officer":"interview - security officer","interview_senior_officer":"interview - senior officer","lifeboat_deck":"lifeboat deck","main_deck":"main deck","mooring_decks":"mooring decks","pre_board":"pre-board","pumproom":"pumproom","steering_gear":"steering gear"};

var _revMap = {"anywhere":"anywhere","aft mooring deck":"aft_mooring_deck","approaching vessel":"approaching_vessel","bow loading area":"bow_loading_area","bridge":"bridge","cargo control room":"cargo_control_room","cargo manifold":"cargo_manifold","chief engineer's office":"chief_engineer_office","compressor room":"compressor_room","documentation":"documentation","emergency headquarters":"emergency_headquarters","engine control room":"engine_control_room","engine room":"engine_room","exterior decks":"exterior_decks","forecastle":"forecastle","internal accommodation":"internal_accommodation","interview - deck officer":"interview_deck_officer","interview - deck rating":"interview_deck_rating","interview - electrician / eto":"interview_electrician_eto","interview - engine rating":"interview_engine_rating","interview - engineer officer":"interview_engineer_officer","interview - galley rating":"interview_galley_rating","interview - rating":"interview_rating","interview - security officer":"interview_security_officer","interview - senior officer":"interview_senior_officer","lifeboat deck":"lifeboat_deck","main deck":"main_deck","mooring decks":"mooring_decks","pre-board":"pre_board","pumproom":"pumproom","steering gear":"steering_gear"};

function tToJs(param) {
  return _map[param];
}

function tFromJs(param) {
  return _revMap[param];
}

var deserFromJS = $$JSON.Field.variadicString("Expected a valid Risq locations", (function (x) {
        return _revMap[x];
      }));

function toString($$location) {
  if ($$location === "lifeboat_deck") {
    return "Lifeboat Deck";
  } else if ($$location === "documentation") {
    return "Documentation";
  } else if ($$location === "compressor_room") {
    return "Compressor Room";
  } else if ($$location === "cargo_manifold") {
    return "Cargo Manifold";
  } else if ($$location === "cargo_control_room") {
    return "Cargo Control Room";
  } else if ($$location === "pre_board") {
    return "Pre-board";
  } else if ($$location === "mooring_decks") {
    return "Mooring Decks";
  } else if ($$location === "pumproom") {
    return "Pumproom";
  } else if ($$location === "chief_engineer_office") {
    return "Chief Engineer's Office";
  } else if ($$location === "exterior_decks") {
    return "Exterior Decks";
  } else if ($$location === "approaching_vessel") {
    return "Approaching Vessel";
  } else if ($$location === "interview_rating") {
    return "Interview - Rating";
  } else if ($$location === "main_deck") {
    return "Main Deck";
  } else if ($$location === "engine_room") {
    return "Engine Room";
  } else if ($$location === "bow_loading_area") {
    return "Bow Loading Area";
  } else if ($$location === "aft_mooring_deck") {
    return "Aft Mooring Deck";
  } else if ($$location === "bridge") {
    return "Bridge";
  } else if ($$location === "interview_electrician_eto") {
    return "Interview - Electrician / ETO";
  } else if ($$location === "interview_deck_rating") {
    return "Interview - Deck Rating";
  } else if ($$location === "interview_security_officer") {
    return "Interview - Security Officer";
  } else if ($$location === "engine_control_room") {
    return "Engine Control Room";
  } else if ($$location === "internal_accommodation") {
    return "Internal Accommodation";
  } else if ($$location === "forecastle") {
    return "Forecastle";
  } else if ($$location === "interview_engineer_officer") {
    return "Interview - Engineer Officer";
  } else if ($$location === "emergency_headquarters") {
    return "Emergency Headquarters";
  } else if ($$location === "anywhere") {
    return "Anywhere";
  } else if ($$location === "interview_engine_rating") {
    return "Interview - Engine Rating";
  } else if ($$location === "interview_senior_officer") {
    return "Interview - Senior Officer";
  } else if ($$location === "interview_galley_rating") {
    return "Interview - Galley Rating";
  } else if ($$location === "steering_gear") {
    return "Steering Gear";
  } else {
    return "Interview - Deck Officer";
  }
}

var Deserializer = $$JSON.MakeDeserializer({
      fields: deserFromJS
    });

var RisqLocation = {
  tToJs: tToJs,
  tFromJs: tFromJs,
  deserFromJS: deserFromJS,
  toString: toString,
  Deserializer: Deserializer
};

var all = [
  "anywhere",
  "aft_mooring_deck",
  "approaching_vessel",
  "bow_loading_area",
  "bridge",
  "cargo_control_room",
  "cargo_manifold",
  "chief_engineer_office",
  "compressor_room",
  "documentation",
  "emergency_headquarters",
  "engine_control_room",
  "engine_room",
  "exterior_decks",
  "forecastle",
  "internal_accommodation",
  "interview_deck_officer",
  "interview_deck_rating",
  "interview_electrician_eto",
  "interview_engine_rating",
  "interview_engineer_officer",
  "interview_galley_rating",
  "interview_rating",
  "interview_security_officer",
  "interview_senior_officer",
  "lifeboat_deck",
  "main_deck",
  "mooring_decks",
  "pre_board",
  "pumproom",
  "steering_gear"
];

var RisqLocations = {
  all: all
};

var Definition = {};

function getUniqueKey(record) {
  return record.question_id + "-" + record.level_id;
}

var include = ReIndexed.MakeIdModel(Definition, {});

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  RisqLocation ,
  RisqLocations ,
  Definition ,
  getUniqueKey ,
  clear ,
  save ,
  $$delete ,
  remove ,
  IdComparator ,
  Id ,
}
/* Identifier Not a pure module */
