// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as PreVettingQuizWidget from "../../prevetting/views/questions/PreVettingQuizWidget.js";
import * as PreVettingIntQuestionWidget from "../../prevetting/views/questions/PreVettingIntQuestionWidget.js";
import * as PreVettingDateQuestionWidget from "../../prevetting/views/questions/PreVettingDateQuestionWidget.js";
import * as PreVettingTextQuestionWidget from "../../prevetting/views/questions/PreVettingTextQuestionWidget.js";
import * as PreVettingPhotoQuestionWidget from "../../prevetting/views/questions/PreVettingPhotoQuestionWidget.js";
import * as PreVettingYesNoQuestionWidget from "../../prevetting/views/questions/PreVettingYesNoQuestionWidget.js";
import * as PreVettingRatingQuestionWidget from "../../prevetting/views/questions/PreVettingRatingQuestionWidget.js";
import * as PreVettingOKNotOKQuestionWidget from "../../prevetting/views/questions/PreVettingOKNotOKQuestionWidget.js";
import * as PreVettingSignatureQuestionWidget from "../../prevetting/views/questions/PreVettingSignatureQuestionWidget.js";
import * as PreVettingWeightInfoQuestionWidget from "../../prevetting/views/questions/PreVettingWeightInfoQuestionWidget.js";
import * as PreVettingMultipleDatesQuestionWidget from "../../prevetting/views/questions/PreVettingMultipleDatesQuestionWidget.js";
import * as PreVettingMultipleTextsQuestionWidget from "../../prevetting/views/questions/PreVettingMultipleTextsQuestionWidget.js";
import * as PreVettingMultipleIntegersQuestionWidget from "../../prevetting/views/questions/PreVettingMultipleIntegersQuestionWidget.js";
import * as PreVettingMultipleDatesTextsQuestionWidget from "../../prevetting/views/questions/PreVettingMultipleDatesTextsQuestionWidget.js";
import * as PreVettingMultipleDatesYesNoQuestionWidget from "../../prevetting/views/questions/PreVettingMultipleDatesYesNoQuestionWidget.js";
import * as PreVettingMultipleDatesIntegersQuestionWidget from "../../prevetting/views/questions/PreVettingMultipleDatesIntegersQuestionWidget.js";

function make(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var match = vnode.attrs.levelStatus;
                var level = match.level;
                var vessel = match.vessel;
                var questions = match.questions;
                if (Prelude.$$Array.isEmpty(questions)) {
                  return ReactDOM.jsx("div", {
                              children: ReactDOM.jsx("p", {
                                    children: "No results",
                                    className: "empty-title h5"
                                  }),
                              className: "empty"
                            });
                }
                var widgets = [ReactDOM.jsx("h5", {
                        children: level.name,
                        className: "p-2 chapter-title"
                      })];
                return Curry._2(Prelude.$$Array.concat, widgets, questions.map(function (param) {
                                var lastNewAnswer = param.lastNewAnswer;
                                var lastAnswer = param.lastAnswer;
                                var question = param.question;
                                var match = question.kind;
                                if (typeof match !== "object") {
                                  if (match === "PhotoQuestion") {
                                    return React.jsx(PreVettingPhotoQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer,
                                                preVettingModule: "RISQ"
                                              });
                                  } else if (match === "TextQuestion") {
                                    return React.jsx(PreVettingTextQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer,
                                                preVettingModule: "RISQ"
                                              });
                                  } else if (match === "DateQuestion") {
                                    return React.jsx(PreVettingDateQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer,
                                                preVettingModule: "RISQ"
                                              });
                                  } else if (match === "SignatureQuestion") {
                                    return React.jsx(PreVettingSignatureQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer,
                                                preVettingModule: "RISQ"
                                              });
                                  } else {
                                    return React.jsx(PreVettingIntQuestionWidget.make, {
                                                question: question,
                                                level: level,
                                                vessel: vessel,
                                                lastNewAnswer: lastNewAnswer,
                                                lastAnswer: lastAnswer,
                                                preVettingModule: "RISQ"
                                              });
                                  }
                                }
                                var variant = match.NAME;
                                if (variant === "OKNotOKQuestion") {
                                  return React.jsx(PreVettingOKNotOKQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer,
                                              preVettingModule: "RISQ"
                                            });
                                } else if (variant === "MultipleTextsQuestion") {
                                  return React.jsx(PreVettingMultipleTextsQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer,
                                              preVettingModule: "RISQ"
                                            });
                                } else if (variant === "RatingQuestion") {
                                  return React.jsx(PreVettingRatingQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer,
                                              preVettingModule: "RISQ"
                                            });
                                } else if (variant === "WeightInfoQuestion") {
                                  return React.jsx(PreVettingWeightInfoQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer,
                                              preVettingModule: "RISQ"
                                            });
                                } else if (variant === "MultipleDatesTextsQuestion") {
                                  return React.jsx(PreVettingMultipleDatesTextsQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer,
                                              preVettingModule: "RISQ"
                                            });
                                } else if (variant === "MultipleDatesYesNoQuestion") {
                                  return React.jsx(PreVettingMultipleDatesYesNoQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer,
                                              preVettingModule: "RISQ"
                                            });
                                } else if (variant === "MultipleIntegersQuestion") {
                                  return React.jsx(PreVettingMultipleIntegersQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer,
                                              preVettingModule: "RISQ"
                                            });
                                } else if (variant === "YesNoQuestion") {
                                  return React.jsx(PreVettingYesNoQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer,
                                              preVettingModule: "RISQ"
                                            });
                                } else if (variant === "MultipleDatesQuestion") {
                                  return React.jsx(PreVettingMultipleDatesQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer,
                                              preVettingModule: "RISQ"
                                            });
                                } else if (variant === "Quiz") {
                                  return React.jsx(PreVettingQuizWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer,
                                              preVettingModule: "RISQ"
                                            });
                                } else {
                                  return React.jsx(PreVettingMultipleDatesIntegersQuestionWidget.make, {
                                              question: question,
                                              level: level,
                                              vessel: vessel,
                                              lastNewAnswer: lastNewAnswer,
                                              lastAnswer: lastAnswer,
                                              preVettingModule: "RISQ"
                                            });
                                }
                              }));
              }));
}

export {
  make ,
}
/* React Not a pure module */
