// Generated by ReScript, PLEASE EDIT WITH CARE

import * as URLs from "../../URLs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Level from "../../tree/Level.js";
import * as Query from "../../Query.js";
import * as React from "../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../common/views/Feather.js";
import * as Finding from "../../tree/Finding.js";
import * as LevelId from "../../tree/LevelId.js";
import * as Mithril from "../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril$1 from "mithril";
import * as AnswerId from "../../tree/AnswerId.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Question from "../../tree/Question.js";
import * as ReactDOM from "../../../libs/rescript-mithril/src/ReactDOM.js";
import * as VesselId from "../../vessel/VesselId.js";
import * as ProfileId from "../../accounts/ProfileId.js";
import * as LocalImage from "../../../libs/LocalImage.js";
import * as PageHeader from "../../common/views/PageHeader.js";
import * as PageLayout from "../../common/views/PageLayout.js";
import * as AnswerValue from "../../tree/AnswerValue.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CommentList from "../../comments/views/CommentList.js";
import * as UserProfile from "../../accounts/UserProfile.js";
import * as State__Memory from "../../state/State__Memory.js";
import * as PreVettingQuery from "../PreVettingQuery.js";
import * as PreVettingAnswer from "../PreVettingAnswer.js";
import * as ToastNotification from "../../common/components/ToastNotification.js";
import * as PreVettingModuleType from "../PreVettingModuleType.js";

async function update(vnode) {
  var answerId = AnswerId.Route.param("answerId");
  var levelId = LevelId.Route.param("levelId");
  var vesselId = VesselId.Route.param("vesselId");
  var preVettingModule = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, PreVettingModuleType.tFromJs(Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Mithril$1.route.param("moduleType"), "RISQ")), "RISQ");
  var Def = {
    preVettingModule: preVettingModule
  };
  var PreVettingQuery$1 = PreVettingQuery.Make(Def);
  var init = PreVettingQuery$1.makeRead();
  var match = await PreVettingQuery$1.read({
        levels: {
          TAG: "Get",
          _0: levelId
        },
        answers: {
          TAG: "Get",
          _0: answerId
        },
        newAnswers: init.newAnswers,
        risqVettingPeriods: init.risqVettingPeriods,
        questions: init.questions,
        findings: init.findings,
        risqQuestionLocations: init.risqQuestionLocations
      });
  var answer = Prelude.default(Prelude.$$Array.last(match.answers), PreVettingAnswer.Defaults.$$null);
  var level = Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.first(match.levels), Level.Defaults.$$null);
  var init$1 = PreVettingQuery$1.makeRead();
  var match$1 = await PreVettingQuery$1.read({
        levels: init$1.levels,
        answers: init$1.answers,
        newAnswers: init$1.newAnswers,
        risqVettingPeriods: init$1.risqVettingPeriods,
        questions: {
          TAG: "Get",
          _0: answer.question_id
        },
        findings: Finding.createdBy(answerId),
        risqQuestionLocations: init$1.risqQuestionLocations
      });
  var newrecord = Caml_obj.obj_dup(Query.makeRead());
  var match$2 = await Query.read((newrecord.userProfiles = {
          TAG: "Get",
          _0: Prelude.default(answer.profile_id, ProfileId.$$null)
        }, newrecord));
  return Mithril.setState(vnode, {
              TAG: "Loaded",
              answer: answer,
              question: Prelude.default(Prelude.$$Array.first(match$1.questions), Question.Defaults.$$null),
              level: level,
              userProfile: Prelude.$$Array.first(match$2.userProfiles),
              vesselId: vesselId,
              finding: Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Prelude.$$Array.first(match$1.findings), Finding.Defaults.$$null),
              preVettingModule: preVettingModule
            });
}

function title() {
  Curry._2(Prelude.OptionExported.$$Option.getWithDefault, PreVettingModuleType.tFromJs(Curry._2(Prelude.OptionExported.$$Option.getWithDefault, Mithril$1.route.param("moduleType"), "RISQ")), "RISQ");
  return "RISQ Section";
}

function make() {
  return Mithril.view(Mithril.oninitAsync(Mithril.component("NotReady"), (function (vnode) {
                    return update(vnode);
                  })), (function (vnode) {
                var match = vnode.state.data;
                if (typeof match !== "object") {
                  return Mithril.empty;
                }
                var preVettingModule = match.preVettingModule;
                var finding = match.finding;
                var vesselId = match.vesselId;
                var userProfile = match.userProfile;
                var level = match.level;
                var question = match.question;
                var answer = match.answer;
                var inspector = userProfile !== undefined ? UserProfile.nameWithRank(userProfile) : "Undefined";
                var evidence = Prelude.$$Array.first(answer.evidences);
                var currentProfile = State__Memory.Current.get().profile;
                var allowResolve = PreVettingAnswer.questionVisibilityGetter(question)(currentProfile, userProfile);
                return React.jsxs(PageLayout.make, {
                            header: Caml_option.some(React.jsx(PageHeader.Full.make, {
                                      content: {
                                        NAME: "Title",
                                        VAL: "Finding Details"
                                      }
                                    })),
                            children: [
                              React.jsx(ToastNotification.make, {}),
                              ReactDOM.jsx("div", {
                                    children: ReactDOM.jsxs("div", {
                                          children: [
                                            ReactDOM.jsxs("div", {
                                                  children: [
                                                    evidence !== undefined ? React.jsx(LocalImage.Resource.make, {
                                                            resource: evidence.id
                                                          }) : Mithril.empty,
                                                    ReactDOM.jsxs("div", {
                                                          children: [
                                                            ReactDOM.jsx("strong", {
                                                                  children: title()
                                                                }),
                                                            ReactDOM.jsx("span", {
                                                                  children: level.name,
                                                                  className: "tw-text-xs tw-mx-2"
                                                                })
                                                          ]
                                                        }),
                                                    ReactDOM.jsxs("div", {
                                                          children: [
                                                            ReactDOM.jsx("strong", {
                                                                  children: "Question: "
                                                                }),
                                                            ReactDOM.jsx("span", {
                                                                  children: question.name,
                                                                  className: "tw-text-xs tw-mx-2"
                                                                })
                                                          ]
                                                        }),
                                                    ReactDOM.jsxs("div", {
                                                          children: [
                                                            ReactDOM.jsx("strong", {
                                                                  children: "Previous answer: "
                                                                }),
                                                            AnswerValue.toHuman(answer.value)
                                                          ]
                                                        }),
                                                    ReactDOM.jsxs("div", {
                                                          children: [
                                                            ReactDOM.jsx("strong", {
                                                                  children: "Inspector: "
                                                                }),
                                                            ReactDOM.jsx("span", {
                                                                  children: inspector,
                                                                  className: "tw-text-xs tw-mx-2"
                                                                })
                                                          ]
                                                        }),
                                                    ReactDOM.jsxs("div", {
                                                          children: [
                                                            ReactDOM.jsx("strong", {
                                                                  children: "Remarks: "
                                                                }),
                                                            ReactDOM.jsx("span", {
                                                                  children: answer.remarks,
                                                                  className: "tw-text-xs tw-mx-2"
                                                                })
                                                          ]
                                                        }),
                                                    React.jsx(CommentList.make, {
                                                          vessel_id: finding.vessel_id,
                                                          object_app: Finding.appName,
                                                          object_type: "finding",
                                                          object_id: finding.id,
                                                          comments: finding.comments
                                                        })
                                                  ],
                                                  className: "tw-p-2"
                                                }),
                                            allowResolve ? ReactDOM.jsxs("button", {
                                                    children: [
                                                      "Rectify finding",
                                                      React.jsx(Feather.make, {
                                                            icon: "arrow_right"
                                                          })
                                                    ],
                                                    className: "tw-w-full tw-flex tw-justify-center tw-items-center tw-shadow-kaiko-card tw-bg-success tw-text-white tw-border-0 tw-absolute tw-bottom-[10px]",
                                                    onClick: (function (param) {
                                                        Mithril$1.route.set(URLs.preVettingAnswerEditorUrl(true, true, undefined, undefined, vesselId, level.id, question, preVettingModule), undefined, {
                                                              replace: true
                                                            });
                                                      })
                                                  }) : Mithril.empty
                                          ],
                                          className: "tw-mx-auto sm:tw-w-10/12 tw-w-full"
                                        }),
                                    className: "tw-flex"
                                  })
                            ]
                          });
              }));
}

export {
  update ,
  title ,
  make ,
}
/* URLs Not a pure module */
