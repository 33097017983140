// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Forms from "../../../common/Forms.js";
import * as React from "../../../../libs/rescript-mithril/src/React.js";
import * as Feather from "../../../common/views/Feather.js";
import * as Mithril from "../../../../libs/rescript-mithril/src/Mithril.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as ReactDOM from "../../../../libs/rescript-mithril/src/ReactDOM.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Mithril__Route from "../../../../libs/rescript-mithril/src/Mithril__Route.js";
import * as DateQuestionUtils from "../../../tree/views/questions/DateQuestionUtils.js";
import * as PreVettingQuestionWidget from "./PreVettingQuestionWidget.js";

async function updateState(vnode, questionState) {
  var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
  var newState;
  if (typeof questionState !== "object") {
    newState = "NotReady";
  } else {
    var question = questionState.question;
    var lastNewAnswer = questionState.lastNewAnswer;
    var match = question.kind;
    var bools_defaults = typeof match === "object" ? (
        match.NAME === "MultipleDatesYesNoQuestion" ? Curry._2(Prelude.$$Array.make, match.VAL.bool_labels.length, undefined) : []
      ) : [];
    var match$1 = vnode.state.data;
    var match$2;
    if (typeof match$1 !== "object") {
      if (createNew) {
        match$2 = [
          [],
          bools_defaults
        ];
      } else if (lastNewAnswer !== undefined) {
        var match$3 = lastNewAnswer.value;
        if (match$3 !== undefined) {
          if (typeof match$3 === "object") {
            if (match$3.NAME === "Value") {
              var match$4 = match$3.VAL;
              if (typeof match$4 === "object") {
                if (match$4.NAME === "MultipleDatesYesNo") {
                  var match$5 = match$4.VAL;
                  match$2 = [
                    match$5[0].map(function (d) {
                          return [
                                  Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, d, DateQuestionUtils.formatDate), ""),
                                  d
                                ];
                        }),
                    match$5[1]
                  ];
                } else {
                  match$2 = [
                    [],
                    bools_defaults
                  ];
                }
              } else {
                match$2 = [
                  [],
                  bools_defaults
                ];
              }
            } else {
              match$2 = [
                [],
                bools_defaults
              ];
            }
          } else {
            match$2 = [
              [],
              bools_defaults
            ];
          }
        } else {
          match$2 = [
            [],
            bools_defaults
          ];
        }
      } else {
        match$2 = [
          [],
          bools_defaults
        ];
      }
    } else {
      match$2 = [
        match$1.dates,
        match$1.bools
      ];
    }
    newState = {
      TAG: "Loaded",
      lastAnswer: questionState.lastAnswer,
      lastNewAnswer: lastNewAnswer,
      question: question,
      level: questionState.level,
      dates: match$2[0],
      bools: match$2[1]
    };
  }
  return Mithril.setState(vnode, newState);
}

function yesButtonClasses(vnode, lastNewAnswer, lastAnswer, index) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return "";
  }
  var match$1 = Curry._2(Prelude.$$Array.get, match.bools, index);
  if (match$1 !== undefined) {
    var match$2 = Caml_option.valFromOption(match$1);
    if (match$2 !== undefined && match$2) {
      return "active";
    }
    
  }
  if (lastNewAnswer !== undefined) {
    var match$3 = lastNewAnswer.value;
    if (match$3 === undefined) {
      return "";
    }
    if (typeof match$3 !== "object") {
      return "";
    }
    if (match$3.NAME !== "Value") {
      return "";
    }
    var match$4 = match$3.VAL;
    if (typeof match$4 === "object" && match$4.NAME === "MultipleDatesYesNo" && Caml_obj.equal(Curry._2(Prelude.$$Array.get, match$4.VAL[1], index), true)) {
      return "active";
    } else {
      return "";
    }
  }
  if (lastAnswer === undefined) {
    return "";
  }
  var match$5 = lastAnswer.value;
  if (match$5 === undefined) {
    return "";
  }
  if (typeof match$5 !== "object") {
    return "";
  }
  if (match$5.NAME !== "Value") {
    return "";
  }
  var match$6 = match$5.VAL;
  if (typeof match$6 === "object" && match$6.NAME === "MultipleDatesYesNo" && Caml_obj.equal(Curry._2(Prelude.$$Array.get, match$6.VAL[1], index), true)) {
    return "bg-gray";
  } else {
    return "";
  }
}

function noButtonClasses(vnode, lastNewAnswer, lastAnswer, index) {
  var match = vnode.state.data;
  if (typeof match !== "object") {
    return "";
  }
  var match$1 = Curry._2(Prelude.$$Array.get, match.bools, index);
  if (match$1 !== undefined) {
    var match$2 = Caml_option.valFromOption(match$1);
    if (match$2 !== undefined && !match$2) {
      return "active";
    }
    
  }
  if (lastNewAnswer !== undefined) {
    var match$3 = lastNewAnswer.value;
    if (match$3 === undefined) {
      return "";
    }
    if (typeof match$3 !== "object") {
      return "";
    }
    if (match$3.NAME !== "Value") {
      return "";
    }
    var match$4 = match$3.VAL;
    if (typeof match$4 === "object" && match$4.NAME === "MultipleDatesYesNo" && Caml_obj.equal(Curry._2(Prelude.$$Array.get, match$4.VAL[1], index), false)) {
      return "active";
    } else {
      return "";
    }
  }
  if (lastAnswer === undefined) {
    return "";
  }
  var match$5 = lastAnswer.value;
  if (match$5 === undefined) {
    return "";
  }
  if (typeof match$5 !== "object") {
    return "";
  }
  if (match$5.NAME !== "Value") {
    return "";
  }
  var match$6 = match$5.VAL;
  if (typeof match$6 === "object" && match$6.NAME === "MultipleDatesYesNo" && Caml_obj.equal(Curry._2(Prelude.$$Array.get, match$6.VAL[1], index), false)) {
    return "bg-gray";
  } else {
    return "";
  }
}

function make() {
  return Mithril.view(Mithril.oncreate(Mithril.component("NotReady"), Forms.Input.focus), (function (vnode) {
                var match = vnode.state.data;
                var tmp;
                if (typeof match !== "object") {
                  tmp = Mithril.empty;
                } else {
                  var bools = match.bools;
                  var dates = match.dates;
                  var level = match.level;
                  var question = match.question;
                  var lastNewAnswer = match.lastNewAnswer;
                  var lastAnswer = match.lastAnswer;
                  var match$1 = question.kind;
                  if (typeof match$1 === "object" && match$1.NAME === "MultipleDatesYesNoQuestion") {
                    var options = match$1.VAL;
                    tmp = ReactDOM.jsxs("div", {
                          children: [
                            ReactDOM.jsx("div", {
                                  children: question.name,
                                  className: "tile-content text-center text-small"
                                }),
                            ReactDOM.jsx("br", {}),
                            options.dates_labels.map(function (label, index) {
                                  var match = Prelude.default(Curry._2(Prelude.$$Array.get, dates, index), [
                                        "",
                                        undefined
                                      ]);
                                  return ReactDOM.jsxs("div", {
                                              children: [
                                                ReactDOM.jsx("label", {
                                                      children: label,
                                                      className: "form-label",
                                                      htmlFor: question.id + String(index)
                                                    }),
                                                ReactDOM.jsx("input", {
                                                      className: "form-input",
                                                      id: question.id + String(index),
                                                      type: "date",
                                                      value: match[0],
                                                      onChange: (function (extra) {
                                                          var match = vnode.state.data;
                                                          if (typeof match !== "object") {
                                                            return ;
                                                          }
                                                          var question = match.question;
                                                          var match$1 = question.kind;
                                                          return Mithril.setState(vnode, {
                                                                      TAG: "Loaded",
                                                                      lastAnswer: match.lastAnswer,
                                                                      lastNewAnswer: match.lastNewAnswer,
                                                                      question: question,
                                                                      level: match.level,
                                                                      dates: typeof match$1 === "object" ? (
                                                                          match$1.NAME === "MultipleDatesYesNoQuestion" ? match$1.VAL.dates_labels.map(function (param, index) {
                                                                                  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Mithril.$$Element.value(Mithril.$$Element.select(vnode, "[id='" + question.id + String(index) + "']")), (function (d) {
                                                                                                    if (d === "") {
                                                                                                      return [
                                                                                                              "",
                                                                                                              undefined
                                                                                                            ];
                                                                                                    } else {
                                                                                                      return [
                                                                                                              d,
                                                                                                              DateQuestionUtils.dateFromString(d)
                                                                                                            ];
                                                                                                    }
                                                                                                  })), [
                                                                                              "",
                                                                                              undefined
                                                                                            ]);
                                                                                }) : []
                                                                        ) : [],
                                                                      bools: match.bools
                                                                    });
                                                        })
                                                    })
                                              ],
                                              className: "m-2"
                                            });
                                }),
                            options.bool_labels.map(function (label, index) {
                                  return ReactDOM.jsxs("div", {
                                              children: [
                                                ReactDOM.jsx("label", {
                                                      children: label,
                                                      className: "form-label col-9"
                                                    }),
                                                ReactDOM.jsxs("div", {
                                                      children: [
                                                        ReactDOM.jsx("div", {
                                                              children: ReactDOM.jsx("button", {
                                                                    children: "Yes",
                                                                    className: "t-yes-answer btn btn-action s-circle text-tiny " + yesButtonClasses(vnode, lastNewAnswer, lastAnswer, index),
                                                                    onClick: (function (param) {
                                                                        if (Curry._3(Prelude.$$Array.set, bools, index, true)) {
                                                                          return Mithril.setState(vnode, {
                                                                                      TAG: "Loaded",
                                                                                      lastAnswer: lastAnswer,
                                                                                      lastNewAnswer: lastNewAnswer,
                                                                                      question: question,
                                                                                      level: level,
                                                                                      dates: dates,
                                                                                      bools: bools
                                                                                    });
                                                                        } else {
                                                                          console.log(String(index) + " out of range!");
                                                                          return ;
                                                                        }
                                                                      })
                                                                  }),
                                                              className: "mr-1"
                                                            }),
                                                        ReactDOM.jsx("div", {
                                                              children: ReactDOM.jsx("button", {
                                                                    children: "No",
                                                                    className: "t-no-answer btn btn-action s-circle text-tiny " + noButtonClasses(vnode, lastNewAnswer, lastAnswer, index),
                                                                    onClick: (function (param) {
                                                                        if (Curry._3(Prelude.$$Array.set, bools, index, false)) {
                                                                          return Mithril.setState(vnode, {
                                                                                      TAG: "Loaded",
                                                                                      lastAnswer: lastAnswer,
                                                                                      lastNewAnswer: lastNewAnswer,
                                                                                      question: question,
                                                                                      level: level,
                                                                                      dates: dates,
                                                                                      bools: bools
                                                                                    });
                                                                        } else {
                                                                          console.log(String(index) + " out of range!");
                                                                          return ;
                                                                        }
                                                                      })
                                                                  })
                                                            })
                                                      ],
                                                      className: "tile-action self-centered col-3 question t-question question-yesno-container"
                                                    })
                                              ],
                                              className: "columns m-2"
                                            });
                                })
                          ],
                          className: "form-group"
                        });
                  } else {
                    tmp = Mithril.empty;
                  }
                }
                return React.jsx(PreVettingQuestionWidget.Editor.make, {
                            getValue: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return {
                                          attachments: []
                                        };
                                }
                                var dates = match.dates.map(function (param) {
                                      return param[1];
                                    });
                                return {
                                        value: {
                                          NAME: "Value",
                                          VAL: {
                                            NAME: "MultipleDatesYesNo",
                                            VAL: [
                                              dates,
                                              match.bools
                                            ]
                                          }
                                        },
                                        attachments: []
                                      };
                              }),
                            allowSave: (function (extra) {
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var match$1 = match.question.kind;
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "MultipleDatesYesNoQuestion") {
                                  return false;
                                }
                                var options = match$1.VAL;
                                var dates = match.dates.map(function (param) {
                                      return param[1];
                                    });
                                if (options.dates_labels.length === Prelude.$$Array.keepSome(dates).length) {
                                  return options.bool_labels.length === Prelude.$$Array.keepSome(match.bools).length;
                                } else {
                                  return false;
                                }
                              }),
                            isDirty: (function (extra) {
                                var createNew = Prelude.default(Mithril__Route.paramBool("createNew"), true);
                                var match = vnode.state.data;
                                if (typeof match !== "object") {
                                  return false;
                                }
                                var bools = match.bools;
                                var dates = match.dates;
                                var lastNewAnswer = match.lastNewAnswer;
                                var hasAnyValue = function () {
                                  if (Curry._2(Prelude.$$Array.some, dates, (function (param) {
                                            return Curry._1(Prelude.OptionExported.$$Option.isSome, param[1]);
                                          }))) {
                                    return true;
                                  } else {
                                    return Curry._2(Prelude.$$Array.some, bools, Prelude.OptionExported.$$Option.isSome);
                                  }
                                };
                                if (createNew || Curry._1(Prelude.OptionExported.$$Option.isNone, lastNewAnswer)) {
                                  return hasAnyValue();
                                }
                                if (lastNewAnswer === undefined) {
                                  return false;
                                }
                                var match$1 = lastNewAnswer.value;
                                if (match$1 === undefined) {
                                  return false;
                                }
                                if (typeof match$1 !== "object") {
                                  return false;
                                }
                                if (match$1.NAME !== "Value") {
                                  return false;
                                }
                                var match$2 = match$1.VAL;
                                if (typeof match$2 !== "object") {
                                  return false;
                                }
                                if (match$2.NAME !== "MultipleDatesYesNo") {
                                  return false;
                                }
                                var match$3 = match$2.VAL;
                                var dates$1 = dates.map(function (param) {
                                      return param[1];
                                    });
                                if (Caml_obj.notequal(match$3[0], dates$1)) {
                                  return true;
                                } else {
                                  return Caml_obj.notequal(match$3[1], bools);
                                }
                              }),
                            updateChildrenState: (function (extra) {
                                return updateState(vnode, extra);
                              }),
                            children: tmp
                          });
              }));
}

function make$1(param) {
  return Mithril.view(Mithril.component(), (function (vnode) {
                var question = vnode.attrs.question;
                var lastAnswer = vnode.attrs.lastAnswer;
                var lastNewAnswer = vnode.attrs.lastNewAnswer;
                var level = vnode.attrs.level;
                var vessel = vnode.attrs.vessel;
                var preVettingModule = vnode.attrs.preVettingModule;
                var isAlreadyAnswered = Belt_Option.isSome(lastNewAnswer);
                return React.jsx(PreVettingQuestionWidget.make, {
                            question: question,
                            level: level,
                            vessel: vessel,
                            lastNewAnswer: lastNewAnswer,
                            lastAnswer: lastAnswer,
                            children: ReactDOM.jsx("button", {
                                  children: React.jsx(Feather.make, {
                                        icon: "calendar"
                                      }),
                                  className: Mithril.$$class([
                                        [
                                          "btn btn-action",
                                          true
                                        ],
                                        [
                                          "active",
                                          isAlreadyAnswered
                                        ],
                                        [
                                          "bg-gray",
                                          Curry._1(Prelude.OptionExported.$$Option.isSome, lastAnswer) && !isAlreadyAnswered
                                        ]
                                      ], undefined),
                                  style: {
                                    border: "none"
                                  },
                                  onClick: (function (extra) {
                                      return PreVettingQuestionWidget.startInspection(vnode, extra);
                                    })
                                }),
                            preVettingModule: preVettingModule
                          });
              }));
}

var Editor = {
  make: make
};

export {
  Editor ,
  make$1 as make,
}
/* Forms Not a pure module */
