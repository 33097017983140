// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as AppSchema from "../../../AppSchema.js";
import * as RisqQuery from "../../../RisqQuery.js";
import * as RisqQuizzesAPI from "../../endpoints/Questions/risq/RisqQuizzesAPI.js";
import * as ServerSyncPipeline from "../../common/ServerSyncPipeline.js";
import * as RisqIntQuestionsAPI from "../../endpoints/Questions/risq/RisqIntQuestionsAPI.js";
import * as ServerSyncQuestions from "../ServerSyncQuestions.js";
import * as RisqDateQuestionsAPI from "../../endpoints/Questions/risq/RisqDateQuestionsAPI.js";
import * as RisqTextQuestionsAPI from "../../endpoints/Questions/risq/RisqTextQuestionsAPI.js";
import * as RisqPhotoQuestionsAPI from "../../endpoints/Questions/risq/RisqPhotoQuestionsAPI.js";
import * as RisqYesNoQuestionsAPI from "../../endpoints/Questions/risq/RisqYesNoQuestionsAPI.js";
import * as RisqRatingQuestionsAPI from "../../endpoints/Questions/risq/RisqRatingQuestionsAPI.js";
import * as RisqOKNotOKQuestionsAPI from "../../endpoints/Questions/risq/RisqOKNotOKQuestionsAPI.js";
import * as RisqSignatureQuestionsAPI from "../../endpoints/Questions/risq/RisqSignatureQuestionsAPI.js";
import * as RisqWeightInfoQuestionsAPI from "../../endpoints/Questions/risq/RisqWeightInfoQuestionsAPI.js";
import * as RisqMultipleDatesQuestionsAPI from "../../endpoints/Questions/risq/RisqMultipleDatesQuestionsAPI.js";
import * as RisqMultipleTextsQuestionsAPI from "../../endpoints/Questions/risq/RisqMultipleTextsQuestionsAPI.js";
import * as RisqMultipleIntegersQuestionsAPI from "../../endpoints/Questions/risq/RisqMultipleIntegersQuestionsAPI.js";
import * as RisqMultipleDatesTextsQuestionsAPI from "../../endpoints/Questions/risq/RisqMultipleDatesTextsQuestionsAPI.js";
import * as RisqMultipleDatesYesNoQuestionsAPI from "../../endpoints/Questions/risq/RisqMultipleDatesYesNoQuestionsAPI.js";
import * as RisqMultipleDatesIntegersQuestionsAPI from "../../endpoints/Questions/risq/RisqMultipleDatesIntegersQuestionsAPI.js";

function $$do(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var levels = AppSchema.Levels.all($$final);
  if (Prelude.$$Array.isEmpty(levels)) {
    return Promise.resolve({
                TAG: "Ok",
                _0: {
                  user: user,
                  vessels: vessels,
                  initial: initial,
                  final: $$final
                }
              });
  } else {
    return Prelude.PromisedResult.map(Prelude.PromisedResult.chain($$final, [
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqYesNoQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertYesNoQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqOKNotOKQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertOKNotOKQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqRatingQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertRatingQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqTextQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertTextQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqIntQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertIntQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqPhotoQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertPhotoQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqSignatureQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertSignatureQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqDateQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertDateQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqMultipleDatesQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertMultipleDateQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqMultipleDatesYesNoQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertMultipleDatesYesNoQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqMultipleDatesIntegersQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertMultipleDatesIntegersQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqMultipleDatesTextsQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertMultipleDatesTextsQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqMultipleIntegersQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertMultipleIntegersQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqMultipleTextsQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertMultipleTextsQuestions($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqQuizzesAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertQuizzes($$final, extra);
                                    }));
                      }),
                    (function ($$final) {
                        return Prelude.PromisedResult.map(RisqWeightInfoQuestionsAPI.getAll(undefined, {
                                        vessel__id__in: vessels.join(",")
                                      }, undefined, user), (function (extra) {
                                      return ServerSyncQuestions.ReadServerState.convertWeightInfoQuestions($$final, extra);
                                    }));
                      })
                  ]), (function ($$final) {
                  return {
                          user: user,
                          vessels: vessels,
                          initial: initial,
                          final: $$final
                        };
                }));
  }
}

var ReadServerState = {
  $$do: $$do
};

function $$do$1(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var init = RisqQuery.makeRead();
  return Prelude.PromisedResult.map(RisqQuery.Safe.read({
                  levels: init.levels,
                  answers: init.answers,
                  newAnswers: init.newAnswers,
                  risqVettingPeriods: init.risqVettingPeriods,
                  questions: {
                    TAG: "AnyOf",
                    _0: "vessel_id",
                    _1: vessels
                  },
                  findings: init.findings,
                  risqQuestionLocations: init.risqQuestionLocations
                }), (function (param) {
                Curry._2(Prelude.$$Array.forEach, param.questions, (function (extra) {
                        return AppSchema.Questions.update(initial, extra);
                      }));
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var FillInitialStatus = {
  $$do: $$do$1
};

function $$do$2(param) {
  var $$final = param.final;
  var initial = param.initial;
  var vessels = param.vessels;
  var user = param.user;
  var init = RisqQuery.makeWrite();
  var actions_levels = init.levels;
  var actions_answers = init.answers;
  var actions_newAnswers = init.newAnswers;
  var actions_risqVettingPeriods = init.risqVettingPeriods;
  var actions_questions = AppSchema.Questions.getActions(initial, $$final);
  var actions_findings = init.findings;
  var actions_risqQuestionLocations = init.risqQuestionLocations;
  var actions = {
    levels: actions_levels,
    answers: actions_answers,
    newAnswers: actions_newAnswers,
    risqVettingPeriods: actions_risqVettingPeriods,
    questions: actions_questions,
    findings: actions_findings,
    risqQuestionLocations: actions_risqQuestionLocations
  };
  RisqQuery.ActionLogging.info("ServerSyncRisqQuestions", actions);
  return Prelude.PromisedResult.map(RisqQuery.Safe.write(actions), (function (param) {
                return {
                        user: user,
                        vessels: vessels,
                        initial: initial,
                        final: $$final
                      };
              }));
}

var UpdateDB = {
  $$do: $$do$2
};

var ApplyLocallyKnownState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var views = [AppSchema.Questions];

var CollectFiles = ServerSyncPipeline.MakeFilesCollector({
      views: views
    });

var CleanUpState = ServerSyncPipeline.MakeNoOpPipelineStep({});

var include = ServerSyncPipeline.MakeStandardAPIPipeline({
      name: "ServerSyncRisqQuestions",
      ReadServerState: ReadServerState,
      FillInitialStatus: FillInitialStatus,
      ApplyLocallyKnownState: ApplyLocallyKnownState,
      UpdateDB: UpdateDB,
      CollectFiles: CollectFiles,
      CleanUpState: CleanUpState
    });

var Query;

var $$do$3 = include.$$do;

export {
  Query ,
  ReadServerState ,
  FillInitialStatus ,
  UpdateDB ,
  $$do$3 as $$do,
}
/* include Not a pure module */
